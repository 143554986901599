/* global document */
(function () {
  'use strict';

  function MultiVideoPlayer() {
    return {
      templateUrl: 'video/video-player/multi-video-player.tpl.html',
      controller: 'MultiVideoPlayerCtrl',
      controllerAs: 'multiVideoPlayer',
      restrict: 'E',
      scope: {
        idVideo: '@',
        snapshotId: '@',
        latitude: '@',
        longitude: '@',
        vehicleId: '@',
        vehiclePrefix: '@',
        vehiclePlate: '@',
        driverName: '@',
        datahora: '@',
        tipoEvento: '@',
        threshold: '@',
        eventDuration: '@',
        eventLevel: '@',
        actualValue: '@',
        isPremiumTelemetryEnabled: '@',
        resolve: '='
      }
    };
  }

  class MultiVideoPlayerCtrl {
    constructor($scope, moment, Authenticator, MultiVideoService, $stateParams, DecoratedVideoPlayerService, FeaturehubJsSdk, FeatureOuService, FeatureHubConfigKeys) {
      this.moment = moment;
      this.authenticator = Authenticator;
      this.multiVideoService = MultiVideoService;
      this.decoratedVideoPlayerService = DecoratedVideoPlayerService;
      this.FeatureOuService = FeatureOuService;
      this.isDecoratedVideoPlayerEnabled = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.DECORATED_VIDEO_PLAYER);
      this.isDecoratedVideoPlayerNAFlagActivated = false;
      this.modalElement = null;
      this.buildVideoParams($scope, $stateParams);
      this.initUserConfig();
    }

    buildVideoParams(scope, stateParams) {
      this.idVideo = scope.resolve ? scope.resolve.idVideo : stateParams.idVideo;
      this.snapshotId = scope.resolve ? scope.resolve.snapshotId : stateParams.snapshotId;
      this.tipoEvento = scope.resolve ? scope.resolve.tipoEvento : stateParams.tipoEvento;
      this.latitude = scope.resolve ? scope.resolve.latitude : stateParams.latitude;
      this.longitude = scope.resolve ? scope.resolve.longitude : stateParams.longitude;
      this.vehicleId = scope.resolve ? scope.resolve.vehicleId : stateParams.vehicleId;
      this.vehiclePrefix = scope.resolve ? scope.resolve.vehiclePrefix : stateParams.vehiclePrefix;
      this.vehiclePlate = scope.resolve ? scope.resolve.vehiclePlate : stateParams.vehiclePlate;
      this.driverName = scope.resolve ? scope.resolve.driverName : stateParams.driverName;
      this.datahora = this.moment(scope.resolve ? scope.resolve.datahora : stateParams.datahora).toISOString();
      this.isDialog = angular.isDefined(scope.resolve);
      this.threshold = scope.resolve ? scope.resolve.threshold : stateParams.threshold;
      this.actualValue = scope.resolve ? scope.resolve.actualValue : stateParams.actualValue;
      this.eventDuration = scope.resolve ? scope.resolve.eventDuration : stateParams.eventDuration;
      this.eventLevel = scope.resolve ? scope.resolve.eventLevel : stateParams.eventLevel;
      this.isPremiumTelemetryEnabled = scope.resolve ? scope.resolve.isPremiumTelemetryEnabled : false;

      this.coordinates = {
        latitude: this.latitude,
        longitude: this.longitude
      };
      this.vehicle = {
        id: this.vehicleId,
        prefixo: this.vehiclePrefix,
        placa: this.vehiclePlate
      };
    }

    initUserConfig() {
      this.authenticator.getUser().then(user => {
        this.user = user;
        this.getDecoratedVideoPlayerConfig(user.uo.id);
      });
    }

    getDecoratedVideoPlayerConfig(userUoId) {
      this.FeatureOuService.getDecoratedVideoPlayerConfig(userUoId).then((decoratedVideoPlayer) => {
        this.isDecoratedVideoPlayerNAFlagActivated = decoratedVideoPlayer;
        this.configurarPlayerMultiVideo();
      }).catch((error) => {
        this.log.error('Error loading featureOu config: ', error);
      });
    }

    shouldDisplayDecoratedVideoPlayer() {
      return this.isDecoratedVideoPlayerEnabled && this.isDecoratedVideoPlayerNAFlagActivated;
    }

    configurarPlayerMultiVideo() {
      /*eslint-disable */
      this.modalElement = document.querySelector('.modal');
      const modalDialogElement = document.querySelector('.modal-dialog'),
          playerContainer = document.querySelector('#video-player-container');

      if (modalDialogElement) {
        modalDialogElement.style.width = '95vw';
        modalDialogElement.style.margin = '0px auto';
      }

      if (playerContainer) {
        this.authenticator.getUser().then(user => {
          if (user) {
            if (this.shouldDisplayDecoratedVideoPlayer()) {
              this.loadDecoratedVideoPlayer(user);
            } else {
              const playerMultiVideo = document.createElement('vfwc-dialog-event-viewer'); /*eslint-enable */

              if (this.snapshotId) {
                playerMultiVideo.snapshotId = this.snapshotId;
              } else {
                playerMultiVideo.idVideo = this.idVideo;
              }
              playerMultiVideo.dataHora = this.datahora;
              playerMultiVideo.user = user;
              playerMultiVideo.tipoEvento = this.tipoEvento;
              playerMultiVideo.coordinates = this.coordinates;
              playerMultiVideo.vehicle = this.vehicle;
              playerMultiVideo.driverName = this.driverName;
              playerMultiVideo.isDialog = this.isDialog;
              playerMultiVideo.threshold = this.threshold;
              playerMultiVideo.actualValue = this.actualValue;
              playerMultiVideo.eventDuration = this.eventDuration;
              playerMultiVideo.eventLevel = this.eventLevel;
              playerMultiVideo.isPremiumTelemetryEnabled = this.isPremiumTelemetryEnabled;
              playerContainer.appendChild(playerMultiVideo);
              this.onWatchedVideo(playerMultiVideo);
              this.onModalClosed(playerMultiVideo);
            }
          }
        });
      }
    }

    loadDecoratedVideoPlayer(user) {
      const isSeparatedVideoScreen = true;
      const props = {
        idVideo: this.idVideo,
        snapshotId: this.snapshotId,
        datahora: this.datahora,
        tipoEvento: this.tipoEvento,
        latitude: this.latitude,
        longitude: this.longitude,
        vehicleId: this.vehicleId,
        vehiclePrefix: this.vehiclePrefix,
        vehiclePlate: this.vehiclePlate,
        driverName: this.driverName,
        threshold: this.threshold,
        eventDuration: this.eventDuration,
        actualValue: this.actualValue,
        eventLevel: this.eventLevel,
        user: user
      };

      this.decoratedVideoPlayerService.loadDecoratedVideoPlayer(props, isSeparatedVideoScreen);
    }

    onWatchedVideo(element) {
      element.addEventListener('markWatched', (event) => {
        const video = event.detail;
        this.multiVideoService.updateVideo(video);
      });
    }

    onModalClosed(element) {
      element.addEventListener('modalClosedEvent', (event) => {
        if (this.modalElement && event.detail.isModalClosed) {
          this.modalElement.remove();
        }
      });
    }
  }

  MultiVideoPlayerCtrl.$inject = ['$scope', 'moment', 'Authenticator', 'MultiVideoService', '$stateParams', 'DecoratedVideoPlayerService', 'FeaturehubJsSdk', 'FeatureOuService', 'FeatureHubConfigKeys'];

  angular
    .module('videoPlayer')
    .controller('MultiVideoPlayerCtrl', MultiVideoPlayerCtrl)
    .directive('multiVideoPlayer', MultiVideoPlayer);
}());
