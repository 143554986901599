(function () {
  'use strict';
  /* global Intl */
  
  const AdminProfile = {
    TRIMBLE_ADMIN: 1,
    ADMIN: 2,
    BI_ADMIN: 1216
  };

  class DeviceHealthCtrl {
    constructor($document, $safetyService, $window, $scope, $log, Authenticator, FeatureOuService, FeaturehubJsSdk, FeatureHubConfigKeys, $state) {
      this.log = $log;
      this.scope = $scope;
      this.window = $window;
      this.document = $document[0];
      this.safetyService = $safetyService;
      this.safetyApp = 'safetyApp';
      this.deviceHealth = './DeviceHealthPageRoute';
      this.safetyElement = 'safety-app-device-health';
      this.container = this.document.getElementById(this.safetyElement);
      this.authenticator = Authenticator;
      this.featureOuService = FeatureOuService;
      this.featurehubJsSdk = FeaturehubJsSdk;
      this.featureHubConfigKeys = FeatureHubConfigKeys
      this.state = $state;
      this.initializePage();
      this.scope.$on('$destroy', () => {
        this.unmountReactComponent();
      });
    }

    initializePage() {
      this.safetyService.initialize()
      .then(() => this.loadPage())
        .catch(error => this.log.error('Initialization failed:', error));
    }

    loadPage() {
      let isAdmin = false,
          userProps = [];
      this.authenticator.getUser()
        .then(user => {
          if (user.perfil && user.perfil.id) {
            isAdmin = [AdminProfile.TRIMBLE_ADMIN, AdminProfile.ADMIN, AdminProfile.BI_ADMIN].includes(user.perfil.id);
          }
          userProps = user;
          return this.safetyService.getComponent(this.safetyApp, this.deviceHealth);
        })
        .then(page => {
          this.enabledNAFeature(userProps.uo.id).then(() => {
            if (this.isFeatureFlagDeviceHealthPageEnabled) {
              this.window.ReactDOM.render(
                this.window.React.createElement(page().default, {isAdmin: isAdmin, user: userProps}),
                this.container
              );
            }
          });
        })
        .catch(error => {
          this.log.error('Error occurred:', error);
        });
    }

    enabledNAFeature(uoId) {
      return this.featureOuService.getProductName(uoId).then((naFeature) => {        
        this.isFeatureFlagDeviceHealthPageEnabled = this.featurehubJsSdk.isFeatureEnabled(this.featureHubConfigKeys.DEVICE_HEALTH_PAGE) && naFeature;
      });
    }

    unmountReactComponent() {
      if (this.window.ReactDOM && this.container) {
        if (this.window.ReactDOM.unmountComponentAtNode(this.container)) {
          this.log.log('React component unmounted');
        } else {
          this.log.log('React component was not mounted.');
        }
      } else {
        this.log.log('Container not found or React component was not mounted.');
      }
    }
  }
  DeviceHealthCtrl.$inject = ['$document', 'safetyService', '$window', '$scope', '$log', 'Authenticator', 'FeatureOuService', 'FeaturehubJsSdk', 'FeatureHubConfigKeys', '$state'];
  angular.module('relatorios.deviceHealth').controller('DeviceHealthCtrl', DeviceHealthCtrl);
}());