(function () {
  'use strict';
  /* global Intl, _, Blob*/
  class HistoricoOcorrenciaCtrl {
    constructor($window, $httpParamSerializer, $filter, HttpServiceV3g, ServiceVfiltro, ObjetosVfiltroService,
      FactoryVfiltro, Keys, VtabelaFactory, uiGridConstants, OcorrenciasEnum, $translate,
      UrlConfigService, AlertasEnum, FeatureFlag) {
      this.serviceVfiltro = ServiceVfiltro;
      this.window = $window;
      this.httpService = HttpServiceV3g;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.keys = Keys;
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.httpParamSerializer = $httpParamSerializer;
      this.filter = $filter;
      this.STATUS_OCORRENCIA = OcorrenciasEnum;
      this.timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.translate = $translate;
      this.urlConfigService = UrlConfigService;
      this.alertasEnum = AlertasEnum;
      this.featureFlag = FeatureFlag;
      this.FIELDS = [
        'id',
        'uoId',
        'dataHora',
        'status',
        'veiculo.id',
        'veiculo.placa',
        'veiculo.prefixo',
        'veiculo.uo.nome',
        'veiculo.uoId',
        'motorista.id',
        'motorista.nome',
        'motorista.uoId',
        'motorista.uo.nome',
        'comentario',
        'usuarioIdAtribuido',
        'ultimoUsuarioAtribuiuTratativa',
        'ultimoUsuarioRecebeuTratativa',
        'ultimoUsuarioResolveu',
        'usuario.login',
        'usuario.id',
        'dataHoraTratativa',
        'dataHoraFinalizacao',
        'alertasGerados',
        'anexo'
      ];

      this.vtabela = null;
      this.exportsPdf = false;

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          time: true,
          required: true,
          dateLimit: 30,
          defaultValues: {
            ontem: {
              active: true
            },
            ultimos7dias: {
              active: true
            },
            ultimos30dias: {
              active: true
            }
          }
        },
        {
          name: 'uoId',
          required: true,
          key: this.keys.uo.name,
          size: 12
        },
        {
          name: 'motoristaIds',
          key: this.keys.motoristas.name,
          size: 12
        }
      ]))
      .then((filtro) => {
        this.filtro = filtro;
        this.consultar();
        this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'relatorioOcorrencia', 'v2');
      });
    }

    initVtabela() {
      let objVtabela = {
        appScopeProvider: this,
        exportsPdf: this.featureFlag.EXPORTAR_PDF_HISTORICO_OCORRENCIAS,
        enableRowSelection: this.featureFlag.EXPORTAR_PDF_HISTORICO_OCORRENCIAS,
        enableRowHeaderSelection: this.featureFlag.EXPORTAR_PDF_HISTORICO_OCORRENCIAS,
        multiSelect: this.featureFlag.EXPORTAR_PDF_HISTORICO_OCORRENCIAS,
        enableSelectAll: this.featureFlag.EXPORTAR_PDF_HISTORICO_OCORRENCIAS,
        exportsPdfDisabledFn: this.handleDisableExportPdf,
        exportsPdfDisabledTooltipFn: this.handleDisableTooltipExportPdf,
        exportsPdfClickFn: (data) => this.handlClickExportPdf(data, this.getParams()),
        isRowSelectable: (e) => !!e.entity.motorista,
        emptyMessage: this.translate.instant('ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.emptyMessage.mensagem') +
          ' <strong>' + this.translate.instant('ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.emptyMessage.dataFiltrada') + '</strong>',
        columnDefs: [
            {
              name: 'motorista',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.motorista',
              type: 'string',
              pinnedLeft: true,
              enableHiding: false,
              filterCellFiltered: true,
              cellFilter: 'withLabelToUndefined:\'ce.tempoReal.quadroDeOcorrencias.common.semIdentificacao\' | translate | splitComposedLabels:true',
              headerCellFilter: 'translate',
              cellTemplate: `
                  <div class='ui-grid-cell-contents'>
                    <motorista-link
                      motorista='row.entity.motorista'
                      motorista-id='row.entity.motoristaId'
                      has-permission='true'
                      index='$index'>
                    </motorista-link>
                  </div>
                `
            },
            {
              name: 'motoristaUo',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.uoMotorista',
              enableHiding: false,
              filterCellFiltered: true,
              headerCellFilter: 'translate'
            },
            {
              name: 'veiculo',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.veiculo',
              enableHiding: false,
              filterCellFiltered: true,
              headerCellFilter: 'translate'
            },
            {
              name: 'veiculoUo',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.uoVeiculo',
              enableHiding: false,
              filterCellFiltered: true,
              headerCellFilter: 'translate'
            },
            {
              name: 'status',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.status',
              filterCellFiltered: true,
              headerCellFilter: 'translate'
            },
            {
              name: 'dataHora',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.dataHora',
              type: 'date',
              filterCellFiltered: true,
              cellFilter: 'date:\'dd/MM/yyyy HH:mm\' | withLabelToUndefined:\'-\'',
              headerCellFilter: 'translate'
            },
            {
              name: 'atribuido',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.atribuido',
              filterCellFiltered: true,
              cellFilter: 'withLabelToUndefined:\'-\'',
              headerCellFilter: 'translate'
            },
            {
              name: 'comentario',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.comentario',
              filterCellFiltered: true,
              cellFilter: 'withLabelToUndefined:\'-\'',
              headerCellFilter: 'translate'
            },
            {
              name: 'dataHoraTratativa',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.datahoraTratativa',
              type: 'date',
              filterCellFiltered: true,
              cellFilter: 'date:\'dd/MM/yyyy HH:mm\' | withLabelToUndefined:\'-\'',
              headerCellFilter: 'translate'
            },
            {
              name: 'ultimoUsuarioAtribuiuTratativa',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.ultimoUsuarioAtribuiuTratativa',
              filterCellFiltered: true,
              cellFilter: 'withLabelToUndefined:\'-\'',
              headerCellFilter: 'translate'
            },
            {
              name: 'ultimoUsuarioRecebeuTratativa',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.ultimoUsuarioRecebeuTratativa',
              filterCellFiltered: true,
              cellFilter: 'withLabelToUndefined:\'-\'',
              headerCellFilter: 'translate'
            },
            {
              name: 'dataHoraResolvida',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.datahoraResolvida',
              type: 'date',
              filterCellFiltered: true,
              cellFilter: 'date:\'dd/MM/yyyy HH:mm\' | withLabelToUndefined:\'-\'',
              headerCellFilter: 'translate'
            },
            {
              name: 'ultimoUsuarioResolveu',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.ultimoUsuarioResolveu',
              filterCellFiltered: true,
              cellFilter: 'withLabelToUndefined:\'-\'',
              headerCellFilter: 'translate'
            },
            {
              name: 'alertasGerados',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.alertas',
              filterCellFiltered: true,
              cellFilter: 'withLabelToUndefined:\'-\'',
              headerCellFilter: 'translate'
            },
            {
              name: 'anexos',
              displayName: 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.colunas.anexos',
              filterCellFiltered: true,
              headerCellFilter: 'translate',
              filter: {
                type: this.uiGridConstants.filter.SELECT,
                condition: this.uiGridConstants.filter.STARTS_WITH,
                selectOptions: [
                  {value: true, label: this.translate.instant('common.sim')},
                  {value: false, label: this.translate.instant('common.nao')}
                ]
              },
              cellTemplate: `
                <div class="ui-grid-cell-contents">
                  <div ng-if='row.entity.anexos' class='anexo-div'>
                    <span class="material-icons icon-anexo">
                      attach_file
                    </span>
                    <span>{{ 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.possuiAnexos' | translate }}</span>
                  </div>
                </div>`
            },
            {
              name: 'detalhe',
              displayName: '',
              enableFiltering: false,
              enableSorting: false,
              pinnedRight: true,
              enableColumnResizing: false,
              enableHiding: false,
              exporterSuppressExport: true,
              width: 70,
              cellTemplate: `
                <div class="ui-grid-cell-contents vtabela-icone-detalhado">
                  <a
                    id='occurrences-history-detail-btn-{{$index}}'
                    class='occurrences-history-detail-btn'
                    ng-href='{{grid.appScope.goToHistOcorrenciaDetalhe(row.entity.id, row.entity.dataHora)}}'>
                    <span class="glyphicon glyphicon-zoom-in" aria-hidden='true'></span>
                  </a>
                </div>`
            }
        ]
      };
      return objVtabela;
    }

    getItemVtabela(item) {
      return {
        id: item.id,
        veiculo: this.filter('prefixoPlaca')(item.veiculo),
        veiculoUo: item.veiculo.uo.nome,
        veiculoId: item.veiculo.id,
        motorista: item.motorista ? item.motorista.nome : null,
        motoristaId: item.motorista ? item.motorista.id : null,
        motoristaUo: item.motorista ? item.motorista.uo.nome : null,
        status: this.translate.instant(this.STATUS_OCORRENCIA[item.status].descricao),
        dataHora: item.dataHora,
        atribuido: item.usuarioAtribuido ? item.usuarioAtribuido.login : null,
        comentario: item.comentario,
        dataHoraTratativa: item.dataHoraTratativa,
        ultimoUsuarioAtribuiuTratativa: item.ultimoUsuarioAtribuiuTratativa ? item.ultimoUsuarioAtribuiuTratativa.login : null,
        ultimoUsuarioRecebeuTratativa: item.ultimoUsuarioRecebeuTratativa ? item.ultimoUsuarioRecebeuTratativa.login : null,
        dataHoraResolvida: item.dataHoraFinalizacao,
        ultimoUsuarioResolveu: item.ultimoUsuarioResolveu ? item.ultimoUsuarioResolveu.login : null,
        alertasGerados: this.getItemTabelaAlertas(item.alertasGerados),
        anexos: item.qtdAnexos ? item.qtdAnexos > 0 : false
      };
    }

    consultar() {
      this.httpService.get(
        `/alerta/ocorrencias?${this.httpParamSerializer(angular.extend(
          this.getParams(),
          {fields: this.FIELDS.join()}
        ))}`
      )
      .then(response => this.setInfoTable(response.data))
        .catch(() => this.vtabela.setError());
    }

    setInfoTable(data) {
      this.vtabela.setData(data.map(row => this.getItemVtabela(row)));
    }

    handleDisableTooltipExportPdf(data) {
      const pdfMessage = 'ce.tempoReal.quadroDeOcorrencias.historicoOcorrencia.pdfMessage.';

      if (data) {
        if (data.length > 10) {
          return `${pdfMessage}max10Ocorrencia`;
        }

        if (!data.every((item) => item.motorista === data[0].motorista)) {
          return `${pdfMessage}differentMotorista`;
        }
      }
      return `${pdfMessage}empty`;
    }

    handleDisableExportPdf(data) {
      if (data) {
        const noneSelected = !data.length,
            moreThan10 = data.length > 10,
            differentMotorista = !data.every((item) => item.motorista === data[0].motorista);

        return noneSelected || moreThan10 || differentMotorista;
      }
      return true;
    }

    handlClickExportPdf(data, params) {
      const extractUniqueIds = (data, key) => [...new Set(data.map(item => item[key]))];

      const driverIds = extractUniqueIds(data, 'motoristaId'),
          vehiclesIds = extractUniqueIds(data, 'veiculoId'),
          occurrenceIds = extractUniqueIds(data, 'id'),
          timezone = this.timezone,
          paramsPdf = {
            start: params.inicio,
            end: params.fim,
            uoId: params.uoId,
            driverIds,
            vehiclesIds,
            occurrenceIds,
            timezone
          },
          httpParams = this.httpParamSerializer(paramsPdf);

      this.downloadPdf(httpParams);
    }

    downloadPdf(httpParams) {
      this.httpService.get(`/alerta/ocorrencias/pdf?${httpParams}`, {responseType: 'blob'})
      .then(result => {
        const contentDispositionHeader = result.headers('content-disposition'),
            fileName = contentDispositionHeader ? contentDispositionHeader.split('filename=')[1] : undefined,
            anchor = angular.element('<a/>'),
            file = new Blob([result.data], {type: 'application/pdf'}),
            pdfBrowserUrl = this.window.URL.createObjectURL(file);
        anchor.attr({
          href: pdfBrowserUrl,
          target: '_blank',
          download: fileName ? fileName : 'export.pdf'
        })[0].click();
        this.window.URL.revokeObjectURL(pdfBrowserUrl);
      });
    }

    getParams() {
      return this.serviceVfiltro.getFiltroBackendNovo(this.filtro);
    }

    getUrlCsv() {
      return `/alerta/ocorrencias/csv?${this.httpParamSerializer(angular.extend(
        this.getParams(),
        {timezone: this.timezone}
      ))}`;
    }

    goToHistOcorrenciaDetalhe(id, dia) {
      return `/relatorios/ocorrencia-detalhes/${id}/${dia}`;
    }

    getItemTabelaAlertas(alertasOcorrencias) {
      if (alertasOcorrencias && alertasOcorrencias.length) {
        return _(alertasOcorrencias)
          .map((alerta) => {
            return {
              tipo: this.translate.instant(this.alertasEnum.getAlerta(alerta.tipo).descricao),
              quantidade: alerta.quantidade
            };
          })
          .sortBy('tipo')
          .groupBy('tipo')
          .map((alertas, tipo) => {
            return `${tipo}: ${alertas.reduce((acc, alerta) => acc + alerta.quantidade, 0)}`;
          })
          .value()
          .join(', ');
      }
      return undefined;
    }
  }

  angular
  .module('relatorios.ocorrencia')
  .controller('HistoricoOcorrenciaCtrl', HistoricoOcorrenciaCtrl);
}());
