/* global L */
(function () {
  'use strict';
  class MotoristaLinkCtrl {
    constructor(Authenticator, ProntuarioLinkService, ServiceVfiltro, Keys) {
      this.prontuarioLinkService = ProntuarioLinkService;
      this.element = null;
      this.serviceVfiltro = ServiceVfiltro;
      this.keys = Keys;
      this.authenticator = Authenticator;
      this.authenticator.getUser().then((user) => {
        this.enableHiperlink = this.prontuarioLinkService.hasRoleToViewProntuario(user);
      });
    }

    getLinkProntuarioMotorista() {
      if (!this.motoristaId || !this.hasPermission) {
        return null;
      }
      let uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id;
      return this.prontuarioLinkService.goToLinkProntuarioSinceFirstDayOfMonth(uoId, this.motoristaId);
    }
  }

  angular
    .module('motoristaLinkModule', [
      'ProntuarioLinkModule',
      'ServiceVfiltroModule'
    ])
    .controller('MotoristaLinkCtrl', MotoristaLinkCtrl)
    .component('motoristaLink', {
      templateUrl: 'directives/motorista-link/motorista-link.tpl.html',
      controller: MotoristaLinkCtrl,
      bindings: {
        motorista: '=',
        motoristaId: '=',
        hasPermission: '=',
        index: '='
      }
    });
}());
