(function () {
  'use strict';

  class BdvCtrl {
    constructor($q, $window, $filter, $httpParamSerializer, Restangular, moment, IDBDV, MockBDV,
      Uos, Veiculos, Bdv, Onboarding, ChecklistEnum, VtabelaFactory, MeasurementUnitConverterService,
      Keys, ServiceVfiltro, FactoryVfiltro, ObjetosVfiltroService, uiGridConstants, $translate, Authenticator, CadastroVeiculoService,
      UrlConfigService, ConvertersService, ConfigEventosService) {
      this.q = $q;
      this.configEventosService = ConfigEventosService;
      this.httpParamSerializer = $httpParamSerializer;
      this.window = $window;
      this.filter = $filter;
      this.convertersService = ConvertersService;
      this.restangular = Restangular;
      this.moment = moment;
      this.mockBDV = MockBDV;
      this.uoService = Uos;
      this.veiculoService = Veiculos;
      this.bdvService = Bdv;
      this.onboarding = Onboarding;
      this.checklistEnum = ChecklistEnum;
      this.vtabelaFactory = VtabelaFactory;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.uiGridConstants = uiGridConstants;
      this.filtro = null;
      this.translate = $translate;
      this.authenticator = Authenticator;
      this.veiculoServiceV2 = CadastroVeiculoService;
      this.urlConfigService = UrlConfigService;

      this.hasSensorAcionado = false;
      this.hasPermissaoChecklist = false;
      this.hasConsumoEletrico = false;
      this.vtabela = null;
      this.tableState = this.objetosVfiltroService.getTableStateUrl();

      this.isDemo = this.onboarding.startOnboarding(IDBDV);
      this.uoService.isDemo = this.isDemo;
      this.veiculoService.isDemo = this.isDemo;

      this.colunaPedalAcionadoFaixa = this.translate.instant('ce.boletim.common.pedalAcionadoFaixa');

      this.tooltipUoNome = this.translate.instant('ce.boletim.diarioVeiculo.tooltipUoNome');
      this.tooltipFrenagemBrusca = this.translate.instant('ce.boletim.diarioVeiculo.tooltipFrenagemBrusca');
      this.tooltipPedalAcionadoFaixa1 = this.translate.instant('ce.boletim.common.tooltipPedalAcionadoFaixa1');
      this.tooltipPedalAcionadoFaixa2 = this.translate.instant('ce.boletim.common.tooltipPedalAcionadoFaixa2');
      this.tooltipPedalAcionadoFaixa3 = this.translate.instant('ce.boletim.common.tooltipPedalAcionadoFaixa3');
      this.tooltipFcwBrakeExtAccDem = this.translate.instant('ce.boletim.diarioVeiculo.colunasTooltips.fcwBrakeExtAccDem');

      this.measurementUnitConverterService = MeasurementUnitConverterService;
      this.authenticator.getUser().then(user => {
        this.measurementUnits = user.measurementUnits;
        this.fuelUnit = this.measurementUnitConverterService.getFuelUnit(this.measurementUnits.fuelMeasurement);
        this.distanceUnit = this.measurementUnitConverterService.getDistanceUnit(this.measurementUnits.distanceMeasurement);
        this.dateTimeFormat = this.measurementUnitConverterService.getDateFormat(this.measurementUnits.dateTimeFormat);
        this.fuelEfficiency = this.measurementUnitConverterService.getFuelEfficiencyUnit(this.measurementUnits.fuelMeasurement);
        this.energyEfficiency = this.measurementUnitConverterService.getEnergyEfficiencyUnit(this.measurementUnits.fuelMeasurement);
        this.numberCellFilter = user.idioma === 'PT_BR' ? 'number:2 | withLabel:\'' : 'numberWithPoint:2 | withNumberLabel:\'';
        this.decimalWithLabelCellFilter = user.idioma === 'PT_BR' ? 'decimalWithLabel:\'' : 'decimalPointWithLabel:\'';
      });

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          dateLimit: 30,
          format: 'YYYY-MM-DD',
          defaultValues: {
            ontem: {
              active: true
            },
            ultimos7dias: {
              active: true
            },
            ultimos30dias: {
              active: true
            }
          }
        },
        {
          name: 'uo',
          key: this.keys.uo.name
        },
        {
          name: 'uoParceira',
          key: this.keys.uoParceira.name,
          size: 6
        },
        {
          name: 'veiculos',
          key: this.keys.veiculos.name,
          ignoreValidation: true,
          size: 6
        }
      ]))
      .then((filtro) => {
        this.filtro = filtro;
        this.roleChecklist = ServiceVfiltro.factoryVfiltro.authenticator.hasRole('CONFIG_VDRIVERS_VISUALIZAR');
        this.consultar(ServiceVfiltro.getFiltroBackendNovo(filtro));
      });
    }

    initVtabela(configEventos) {
      let drilDownCol, drilDownSorting, objVtabela = {
            exporterPdfFilename: 'Boletim Diário do Veículo PDF.pdf',
            exporterCsvFilename: 'Boletim Diário do Veículo CSV.csv',
            exporterExcelFilename: 'Boletim Diário do Veículo XLSX.xlsx',
            appScopeProvider: this,
            enableRowSelection: false,
            enableRowHeaderSelection: false,
            multiSelect: false,
            enableSelectAll: false,
            showColumnFooter: true,
            columnDefs: [
                {
                  name: 'prefixo',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.prefixo',
                  type: 'string',
                  filterCellFiltered: true,
                  enableHiding: false
                },
                {
                  name: 'uoNome',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.uoNome',
                  type: 'string',
                  filterCellFiltered: true,
                  enableHiding: false,
                  headerCellClass: 'icon-info-tooltip',
                  headerTooltip: this.tooltipUoNome
                },
                {
                  name: 'placa',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.placa',
                  type: 'string',
                  pinnedLeft: true,
                  filterCellFiltered: true,
                  enableHiding: false,
                  width: 150,
                  aggregationTotalTitle: this.translate.instant('ce.boletim.common.total')
                },
                {
                  name: 'motorista',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.motoristas',
                  enableHiding: false,
                  filterCellFiltered: true,
                  cellFilter: 'withLabelToUndefined:\'ce.common.motorista.semIdentificacao\' | translate | splitComposedLabels:true'
                },
                {
                  name: 'dia',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.dia',
                  type: 'date',
                  cellFilter: 'date:\'' + this.dateTimeFormat + '\'',
                  filterCellFiltered: true,
                  enableHiding: false,
                  cellClass: 'text-center'
                },
                {
                  name: 'distanciaConvertida',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.distanciaPercorrida',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellFilter: this.numberCellFilter + this.distanceUnit + '\'',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'tempoIgnicaoLigada',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.tempoIgnicaoLigada',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  enableFiltering: true,
                  filterCellFiltered: true
                },
                {
                  name: 'tempoVigente',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.tempoEmVigencia',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  enableFiltering: true,
                  filterCellFiltered: true
                },
                {
                  name: 'velocidade',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.excessoVelocidade',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'aceleracoesBruscas',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.aceleracoesBruscas',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.aceleracao && !configEventos.aceleracao.enabled
                },
                {
                  name: 'frenagensBruscas',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.frenagensBruscas',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  enableFiltering: true,
                  filterCellFiltered: true,
                  headerCellClass: 'icon-info-tooltip',
                  headerTooltip: this.tooltipFrenagemBrusca
                },
                {
                  name: 'frenagensComAcionamentoPedal',
                  displayName: 'ce.enum.common.frenagemBruscaComPedal',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  enableFiltering: true,
                  filterCellFiltered: true
                },
                {
                  name: 'frenagensSemAcionamentoPedal',
                  displayName: 'ce.enum.common.frenagemBruscaSemPedal',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  enableFiltering: true,
                  filterCellFiltered: true
                },
                {
                  name: 'curvasBruscas',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.curvasBruscas',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.curva && !configEventos.curva.enabled
                },
                {
                  name: 'noSeatbelt',
                  displayName: 'ce.enum.common.semCinto',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'yawning',
                  displayName: 'ce.enum.eventos.bocejo',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'absentDriver',
                  displayName: 'ce.enum.common.semMotorista',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.motoristaAusente && !configEventos.motoristaAusente.enabled
                },
                {
                  name: 'smoking',
                  displayName: 'ce.enum.common.usoDeCigarro',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'distraction',
                  displayName: 'ce.enum.common.distracao',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'fatique',
                  displayName: 'ce.enum.common.fadiga',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'cellphoneUsage',
                  displayName: 'ce.enum.common.usoDeCelular',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'unsafeDistance',
                  displayName: 'ce.enum.common.distanciaPerigosa',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.distanciaPerigosa && !configEventos.distanciaPerigosa.enabled
                },
                {
                  name: 'obstructedCamera',
                  displayName: 'ce.enum.common.cameraObstruida',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'illegalOvertaking',
                  displayName: 'ce.enum.eventos.ultrapassagemIlegal',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.ultrapassagemIlegal && !configEventos.ultrapassagemIlegal.enabled
                },
                {
                  name: 'noSafetyCone',
                  displayName: 'ce.enum.eventos.semCone',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.semCone && !configEventos.semCone.enabled
                },
                {
                  name: 'noSafetyGlasses',
                  displayName: 'ce.enum.eventos.SemOculos',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.semOculos && !configEventos.semOculos.enabled
                },
                {
                  name: 'noGloves',
                  displayName: 'ce.enum.eventos.SemLuvas',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.semLuvas && !configEventos.semLuvas.enabled
                },
                {
                  name: 'ride',
                  displayName: 'ce.enum.eventos.carona',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.carona && !configEventos.carona.enabled
                },
                {
                  name: 'followingDistance',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.followingDistance',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.followingDistance.enabled)
                },
                {
                  name: 'collisionMitigation',
                  displayName: 'ce.boletim.diarioMotorista.colunas.collisionMitigation',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.collisionMitigation.enabled)
                },
                {
                  name: 'fcwBrakeExtAccDem',
                  displayName: 'ce.boletim.diarioMotorista.colunas.fcwBrakeExtAccDem',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.fcwBrakeExtAccDem.enabled),
                  headerCellClass: 'icon-info-tooltip',
                  headerTooltip: this.tooltipFcwBrakeExtAccDem
                },
                {
                  name: 'hapticWarning',
                  displayName: 'ce.boletim.diarioMotorista.colunas.hapticWarning',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.hapticWarning.enabled)
                },
                {
                  name: 'ropBrake',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.ropBrake',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.ropBrake.enabled)
                },
                {
                  name: 'ropEngine',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.ropEngine',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.ropEngine.enabled)
                },
                {
                  name: 'ycBrake',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.ycBrake',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.ycBrake.enabled)
                },
                {
                  name: 'ycEngine',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.ycEngine',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: !(configEventos && configEventos.ycEngine.enabled)
                },
                {
                  name: 'banguelas',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.banguela',
                  type: 'number',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.banguela && !configEventos.banguela.enabled
                },
                {
                  name: 'motorOcioso',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.paradoLigado',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.paradoLigado && !configEventos.paradoLigado.enabled
                },
                {
                  name: 'consumoCombustivel',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.consumoCombustivel',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: this.decimalWithLabelCellFilter + this.fuelUnit + '\'',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'mediaConsumo',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.mediaConsumo',
                  cellClass: 'text-center',
                  cellFilter: this.numberCellFilter + this.fuelEfficiency + '\'',
                  filterCellFiltered: true
                },
                {
                  name: 'consumoEletrico',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.consumoEletrico',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: this.decimalWithLabelCellFilter + 'kWh' + '\'',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  visible: this.hasConsumoEletrico
                },
                {
                  name: 'mediaConsumoEletrico',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.mediaConsumoEletrico',
                  cellClass: 'text-center',
                  cellFilter: this.numberCellFilter + this.energyEfficiency + '\'',
                  filterCellFiltered: true,
                  visible: this.hasConsumoEletrico
                },
                {
                  name: 'rpmMarchaLenta',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.rpmMarchaLenta',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'rpmAbaixoVerde',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.rpmAbaixoVerde',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'rpmVerdeEconomico',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.rpmVerdeEconomico',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'rpmVerdePotencia',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.rpmVerdePotencia',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'rpmAmarelo',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.rpmAmarelo',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'rpmVermelho',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.rpmVermelho',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'rpmExcessivo',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.rpmExcessivo',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  isDisabled: configEventos && configEventos.rpmExcessivo && !configEventos.rpmExcessivo.enabled
                },
                {
                  name: 'pedalAcionadoFaixa1',
                  displayName: this.colunaPedalAcionadoFaixa + ' 1',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  headerCellClass: 'icon-info-tooltip',
                  headerTooltip: this.tooltipPedalAcionadoFaixa1
                },
                {
                  name: 'pedalAcionadoFaixa2',
                  displayName: this.colunaPedalAcionadoFaixa + ' 2',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  headerCellClass: 'icon-info-tooltip',
                  headerTooltip: this.tooltipPedalAcionadoFaixa2
                },
                {
                  name: 'pedalAcionadoFaixa3',
                  displayName: this.colunaPedalAcionadoFaixa + ' 3',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  type: 'number',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  headerCellClass: 'icon-info-tooltip',
                  headerTooltip: this.tooltipPedalAcionadoFaixa3
                },
                {
                  name: 'logradouroInicial',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.logradouroInicial',
                  filterCellFiltered: true
                },
                {
                  name: 'logradouroFinal',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.logradouroFinal',
                  filterCellFiltered: true
                },
                {
                  name: 'hodometroInicial',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.hodometroInicial',
                  cellFilter: this.decimalWithLabelCellFilter + this.distanceUnit + '\'',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  visible: false
                },
                {
                  name: 'hodometroFinal',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.hodometroFinal',
                  cellFilter: this.decimalWithLabelCellFilter + this.distanceUnit + '\'',
                  cellClass: 'text-center',
                  filterCellFiltered: true
                },
                {
                  name: 'tempoMovimento',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.tempoEmMovimento',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  visible: false
                },
                {
                  name: 'tempoParado',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.tempoParado',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  visible: false
                },
                {
                  name: 'tempoGpsInvalido',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.tempoGpsInvalido',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  visible: false
                },
                {
                  name: 'tempoModuloDesligado',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.tempoEquipDesligado',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  visible: false
                },
                {
                  name: 'tempoBatendoTransmissao',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.tempoBatendoTransmissao',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  visible: false
                },
                {
                  name: 'tempoFreioMotor',
                  displayName: 'ce.boletim.diarioVeiculo.colunas.tempoFreioMotor',
                  aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
                  cellFilter: 'humanizedDuration',
                  cellClass: 'text-center',
                  filterCellFiltered: true,
                  visible: false
                },
                {
                  name: 'detalhe',
                  displayName: '',
                  enableFiltering: false,
                  enableSorting: false,
                  pinnedRight: true,
                  enableColumnResizing: false,
                  enableHiding: false,
                  exporterSuppressExport: true,
                  width: 70,
                  cellTemplate: `
                    <div
                      class="ui-grid-cell-contents vtabela-icone-detalhado"
                      ng-if="row.entity.distancia > 0 || row.entity.tempoIgnicaoLigada > 0">
                      <a
                        id='btn-detalhes-{{$index}}'
                        ng-href='{{grid.appScope.getUrlBDVDetalhe(row.entity.id, row.entity.dia, row.entity.motorista)}}'>
                        <span class="glyphicon glyphicon-zoom-in" aria-hidden='true'></span>
                      </a>
                    </div>`
                }
            ].filter(x => x.isDisabled !== true)
          };

      if (this.hasSensorAcionado) {
        objVtabela.columnDefs.push({
          name: 'tempoSensorDesengate',
          displayName: 'ce.boletim.diarioVeiculo.colunas.sensorAcionado',
          sortWithUndefinedValues: true,
          aggregationTypeTotal: this.uiGridConstants.aggregationTypes.sum,
          cellTemplate: `
            <div class="ui-grid-cell-contents text-center">
              <span title="{{ row.entity.tempoSensorDesengate | humanizedOrUndefinedWithLabel:\'ce.boletim.diarioVeiculo.sensorAcionado.naoSeAplica' | translate }}">
                {{ row.entity.tempoSensorDesengate | humanizedOrUndefinedWithLabel:\'N/A\' }}
              </span>
            </div>`,
          cellFilter: 'humanizedOrUndefinedWithLabel:\'N/A\'',
          cellClass: 'text-center',
          filterCellFiltered: true
        });
      }

      if (this.hasPermissaoChecklist && this.roleChecklist) {
        objVtabela.columnDefs.push({
          name: 'checklist',
          displayName: 'ce.boletim.diarioVeiculo.colunas.checklist',
          enableFiltering: false,
          enableHiding: false,
          customSort: true,
          exporterSuppressExport: true,
          cellTemplate: `
            <div class="ui-grid-cell-contents text-center">
              <a
                ng-if='row.entity.checklist && row.entity.checklist !== grid.appScope.checklistEnum.NAO_APLICA.id'
                ng-href="{{grid.appScope.getUrlChecklist(row.entity)}}">
                <i class="trimble-icon trimble-checklist" ng-class='grid.appScope.checklistEnum.getTipoChecklist(row.entity.checklist).class'></i>
              </a>
              <span
                uib-tooltip="{{ 'ce.boletim.common.checklist.naoPreenchido' | translate }}"
                ng-if='!row.entity.checklist || row.entity.checklist === grid.appScope.checklistEnum.NAO_APLICA.id'> - </span>
            </div>`
        });
      }

      drilDownSorting = this.getDrilDownSortingDirection(this.tableState);
      drilDownCol = objVtabela.columnDefs.find(x => this.isEventoVtabela(x.name, this.tableState.sort.predicate));

      if (drilDownSorting && drilDownCol) {
        angular.merge(
          drilDownCol,
          drilDownSorting
        );
      }

      return objVtabela;
    }

    getDrilDownSortingDirection(tableState) {
      if (tableState && tableState.sort.reverse) {
        return {
          sort: {
            direction: this.uiGridConstants.DESC
          }
        };
      }
      return {
        sort: {
          direction: this.uiGridConstants.ASC
        }
      };
    }

    isEventoVtabela(eventName, urlEvent) {
      const isSpeedEvent = eventName === 'velocidade',
          isUrlExVelocidadeFaixa = urlEvent === 'exVelocidadeFaixa1' ||
            urlEvent === 'exVelocidadeFaixa2' ||
            urlEvent === 'exVelocidadeFaixa3';

      return eventName.toLowerCase() === urlEvent.toLowerCase() || isUrlExVelocidadeFaixa && isSpeedEvent;
    }

    getItemVtabela(item) {
      return {
        id: item.veiculo.id,
        prefixo: item.veiculo.prefixo,
        uoNome: item.uoNome,
        placa: item.veiculo.placa,
        motorista: item.motoristas,
        dia: item.dia,
        distancia: item.distanciaPercorrida,
        distanciaConvertida: item.distanciaPercorridaConvertida,
        tempoIgnicaoLigada: item.tempoDirecao,
        tempoVigente: item.tempoVigente,
        velocidade: item.excessosVelocidade,
        aceleracoesBruscas: item.aceleracoes,
        frenagensBruscas: item.frenagens,
        frenagensComAcionamentoPedal: item.frenagensComAcionamentoPedal,
        frenagensSemAcionamentoPedal: item.frenagensSemAcionamentoPedal,
        curvasBruscas: item.curvas,
        noSeatbelt: item.noSeatbelt,
        yawning: item.yawning,
        absentDriver: item.absentDriver,
        smoking: item.smoking,
        distraction: item.distraction,
        fatique: item.fatique,
        cellphoneUsage: item.cellphoneUsage,
        unsafeDistance: item.unsafeDistance,
        obstructedCamera: item.obstructedCamera,
        illegalOvertaking: item.illegalOvertaking,
        noSafetyCone: item.noSafetyCone,
        noSafetyGlasses: item.noSafetyGlasses,
        noGloves: item.noGloves,
        ride: item.ride,
        banguelas: item.banguelas,
        rpmExcessivo: item.rpmExcessivos,
        rpmMarchaLenta: item.tempoRpmMarchaLenta,
        rpmAbaixoVerde: item.tempoRpmAbaixoVerde,
        rpmVerdeEconomico: item.tempoRpmVerdeEconomica,
        rpmVerdePotencia: item.tempoRpmVerdePotencia,
        rpmAmarelo: item.tempoRpmAmarelo,
        rpmVermelho: item.tempoRpmVermelho,
        motorOcioso: item.motorOcioso,
        tempoSensorDesengate: item.tempoSensorDesengate,
        consumoCombustivel: item.consumoCombustivel,
        consumoEletrico: item.consumoEletrico,
        logradouroInicial: item.logradouroInicio,
        logradouroFinal: item.logradouroFim,
        hodometroInicial: item.hodometroInicio ? item.hodometroInicio : item.hodometroInicioCalculado,
        hodometroFinal: item.hodometroFimCalculado ? item.hodometroFimCalculado : item.hodometroFim,
        checklist: item.checklist,
        mediaConsumo: item.mediaConsumo,
        mediaConsumoEletrico: item.mediaConsumoEletrico,
        tempoMovimento: item.tempoMovimento,
        tempoParado: item.tempoParado,
        tempoGpsInvalido: item.tempoGpsInvalido,
        tempoModuloDesligado: item.tempoModuloDesligado,
        tempoBatendoTransmissao: item.tempoBatendoTransmissao,
        tempoFreioMotor: item.tempoFreioMotor,
        pedalAcionadoFaixa1: item.pedalFreioDescidaFaixa1Qtd,
        pedalAcionadoFaixa2: item.pedalFreioDescidaFaixa2Qtd,
        pedalAcionadoFaixa3: item.pedalFreioDescidaFaixa3Qtd,
        followingDistance: item.followingDistance,
        ropBrake: item.ropBrake,
        ropEngine: item.ropEngine,
        ycBrake: item.ycBrake,
        ycEngine: item.ycEngine,
        collisionMitigation: item.collisionMitigation,
        fcwBrakeExtAccDem: item.fcwBrakeExtAccDem,
        hapticWarning: item.hapticWarning
      };
    }

    consultar(filtro) {
      if (this.isDemo) {
        this.setInfoTable(
          this.mockBDV
            .filter(x => x.id === this.serviceVfiltro.filtroCompleto
              .find(item => item.key === this.keys.veiculos.name).values[0].id
            )[0].data.list
          );
      } else {
        this.q.all([
          this.bdvService.one().get(filtro),
          this.configEventosService.getConfigEventos(),
          this.consultarPermissaoChecklist(filtro),
          this.consultarSensorAcionado()
        ])
        .then(([data, configEventos]) => {
          this.hasConsumoEletrico = data.list.some((registro) => angular.isDefined(registro.mediaConsumoEletrico));
          const tabela = this.initVtabela(configEventos);
          this.vtabela = this.vtabelaFactory.get(tabela, this.serviceVfiltro.factoryVfiltro.user, 'relatorioBDV', 'v3|' + this.serviceVfiltro.factoryVfiltro.user.uo.id + '|' + this.disabledColumnsHash(tabela));
          this.setInfoTable(data.list);
        })
        .catch(() => this.vtabela.setError());
      }
    }

    disabledColumnsHash(tabela) {
      return this.hashCode(tabela.columnDefs.filter(x => x.isDisabled !== true).map(x => x.name).join(','));
    }

    hashCode(str) {
      let hash = 0;
      for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
      }
      return hash;
    }

    setInfoTable(data) {
      let convertedData = this.convertMeasurements(data);
      this.vtabela.setData(convertedData.map(i => this.getItemVtabela(i)));
    }

    convertMeasurements(data) {
      return data.map(record => {
        let recordCopy = Object.assign({}, record);

        const conversionFactors = {
          distanciaPercorrida: 0.621371,
          consumoCombustivel: 0.264172,
          mediaConsumo: 2.352,
          distanciaPercorridaConvertida: 0.621371,
          mediaConsumoEletrico: 0.621371,
          hodometroFimCalculado: 0.621371,
          hodometroInicio: 0.621371
        };

        for (let field in conversionFactors) {
          if (field in recordCopy) {
            let isDistanceField = ['distanciaPercorrida', 'distanciaPercorridaConvertida', 'mediaConsumoEletrico', 'hodometroFimCalculado', 'hodometroInicio'
            ].includes(field),
                isFuelField = ['consumoCombustivel', 'mediaConsumo'].includes(field);
            if (isDistanceField && this.measurementUnits.distanceMeasurement !== 'KM' ||
                isFuelField && this.measurementUnits.fuelMeasurement !== 'LITERS') {
              recordCopy[field] *= conversionFactors[field];
            }
          }
        }
        return recordCopy;
      });
    }

    consultarPermissaoChecklist(filtro) {
      return this.q((resolve) => {
        this.restangular.one(`/mobile/config/uo/${filtro.uo}/vdrivers`).get()
        .then((item) => {
          this.hasPermissaoChecklist = item.vdriverConfig.habilitarChecklist;
          resolve();
        })
        .catch(() => {
          this.hasPermissaoChecklist = false;
          resolve();
        });
      });
    }

    consultarSensorAcionado() {
      return this.authenticator.getUser().then(user => {
        const usuarioUoId = user.plain().uo.id;

        this.veiculoServiceV2.hasSensor(usuarioUoId).then(hasSensor => {
          this.hasSensorAcionado = hasSensor;
        });
      });
    }

    getUrlBDVDetalhe(id, dia, motorista) {
      return `/relatorios/bdv/detalhes/${id}/${dia}?${this.isDemo ? `demo&motorista=${motorista}` : ''}`;
    }

    getUrlChecklist(bdv) {
      return `/relatorios/bdv/checklist/${bdv.id}/${bdv.dia}`;
    }

    getUrlCsv() {
      return `/boletim/bv/csv?${this.httpParamSerializer(this.serviceVfiltro.getFiltroBackendNovo(this.filtro))}`;
    }
  }

  /**
   * @ngdoc object
   * @name bdv.controller:BdvCtrl
   *
   * @description
   *
   */
  angular
    .module('relatorios.bdv')
    .controller('BdvCtrl', BdvCtrl);
}());
