/* global document */
(function () {
  'use strict';
  class SolicitacaoVideosCtrl {
    constructor(ServiceVfiltro, Authenticator, FactoryVfiltro, ProntuarioLinkService, Keys, moment, $translate) {
      this.authenticator = Authenticator;
      this.keys = Keys;
      this.moment = moment;
      this.translate = $translate;
      this.prontuarioLinkService = ProntuarioLinkService;

      ServiceVfiltro.init(FactoryVfiltro.get([
        {
          key: this.keys.dateTime.name,
          time: true,
          maxDate: this.moment().endOf('day').format(),
          defaultValues: {
            hoje: {
              active: true
            },
            ultimos7dias: {
              active: true
            },
            ontem: {
              active: true
            }
          }
        },
        {
          key: this.keys.uo.name
        },
        {
          key: this.keys.veiculos.name
        },
        {
          key: this.keys.statusVideosSolicitacao.name
        },
        {
          label: this.translate.instant('ce.configuracao.eventos.menuLabel'),
          key: this.keys.tipos.name,
          hasNivel: false,
          showEpiUnified: true
        }
      ]))
        .then((filtro) => {
          this.init(filtro);
        });
      this.authenticator.getUser().then(user => {
        this.validShowLinkDriverRecord = this.prontuarioLinkService.hasRoleToViewProntuario(user);
      });
    }

    init(filtro) {
      /*eslint-disable */
      this.authenticator.getUser().then(user => {
        const tableSolicitacaoVideosElement = document.querySelector('#table-solicitacao-videos'),
          vfwcTableSolicitacaoVideosElement = document.createElement('vfwc-solicitacao-videos'),
          vehiclesFiltered = filtro.find(f => f.key === 'veiculos'),
          eventsFiltered = filtro.find(f => f.key === 'tipos'),
          statusVideoFiltered = filtro.find(f => f.key === 'statusVideosSolicitacao'),
          vehiclesId = vehiclesFiltered ? vehiclesFiltered.values.map(vehicle => vehicle.id) : vehiclesFiltered,
          eventsId = eventsFiltered ? eventsFiltered.values.map(event => event.id) : eventsFiltered,
          statusVideoId = statusVideoFiltered ? statusVideoFiltered.values.map(status => status.id) : statusVideoFiltered;
        vfwcTableSolicitacaoVideosElement.uoId = Number(filtro.find(f => f.key === 'uo').value.id);
        vfwcTableSolicitacaoVideosElement.vehiclesIds = vehiclesId;
        vfwcTableSolicitacaoVideosElement.events = eventsId;
        vfwcTableSolicitacaoVideosElement.status = statusVideoId;
        vfwcTableSolicitacaoVideosElement.startDate = filtro.find(f => f.key === 'dateTime').value.startDate;
        vfwcTableSolicitacaoVideosElement.endDate = filtro.find(f => f.key === 'dateTime').value.endDate;
        vfwcTableSolicitacaoVideosElement.user = user;
        vfwcTableSolicitacaoVideosElement.hideHeaderTopLine = false;
        vfwcTableSolicitacaoVideosElement.enableHiperlink = this.validShowLinkDriverRecord;

        if (tableSolicitacaoVideosElement) {
          tableSolicitacaoVideosElement.appendChild(vfwcTableSolicitacaoVideosElement);
        }
      });
      /*eslint-enable */
    }
  }

  angular
    .module('relatorios.videoLibrary.solicitacaoVideos')
    .controller('SolicitacaoVideosCtrl', SolicitacaoVideosCtrl);
}());
