(function () {
  'use strict';

  function pesquisaPontoReferenciaDirective() {
    return {
      templateUrl: 'directives/map-control/pesquisa-ponto-referencia/pesquisa-ponto-referencia.tpl.html',
      controller: 'PesquisaPontoReferenciaCtrl',
      controllerAs: 'ctrl',
      restrict: 'E'
    };
  }

  class PesquisaPontoReferenciaCtrl {
    constructor($scope, $window, HttpServiceV3g, PontosReferenciaMap, PontosReferenciaMenu,
      PontosReferencia, PontosReferenciaFiltro, PesquisaPontoReferenciaService, CercaEletronicaMapService,
      BuscaPorRegiaoMapService, BuscaRegiaoService, moment, ServiceVfiltro, $httpParamSerializer, Keys, $state,
      $rootScope, $location, GoogleTagManagerUtils) {
      this.scope = $scope;
      this.window = $window;
      this.httpService = HttpServiceV3g;
      this.pontosReferenciaMapService = PontosReferenciaMap;
      this.pontosReferenciaMenuService = PontosReferenciaMenu;
      this.pontosReferenciaService = PontosReferencia;
      this.pontosReferenciaFiltroService = PontosReferenciaFiltro;
      this.pesquisaService = PesquisaPontoReferenciaService;
      this.validaPontos = true;
      this.validaPontoArea = true;
      this.validaPesquisaLogradouro = true;
      this.tabLogradouro = false;
      this.tabPontoReferencia = true;
      this.tabBuscaPorRegiao = false;
      this.isAreaBuscaRegiaoCriada = false;
      this.cercaEletronicaMapService = CercaEletronicaMapService;
      this.buscaPorRegiaoMapService = BuscaPorRegiaoMapService;
      this.buscaRegiaoService = BuscaRegiaoService;
      this.moment = moment;
      this.serviceVfiltro = ServiceVfiltro;
      this.httpParamSerializer = $httpParamSerializer;
      this.keys = Keys;
      this.state = $state;
      this.rootScope = $rootScope;
      this.location = $location;
      this.isDemo = this.location.search().demo;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.typePonto = {
        id: 'PONTO_REFERENCIA',
        label: 'Ponto de Referência'
      };
      this.typeCerca = {
        id: 'CERCAS_ELETRONICAS',
        label: 'Cerca Eletrônica'
      };
      this.dateOptions = {
        maxDate: this.moment().startOf('day'),
        startingDay: 0,
        showWeeks: false
      };
      this.paginasPermiteBuscaRegiao = [
        'root.mapasGrid'
      ];
      this.init();
    }

    init() {
      this.pontosReferenciaFiltroService.selecionarFiltro = false;
      this.pontosReferenciaFiltroService.init();
      this.scope.$on('$destroy', () => {
        this.buscaPorRegiaoMapService.removeLayerMap();
        this.setTabPontoReferencia();
      });
      this.rootScope.$on('clica-busca-regiao', () => this.setTabBuscaPorRegiao());
      this.rootScope.$on('desenha-area', () => this.desenhaArea());
      this.rootScope.$on('busca-por-regiao', () => this.buscaPorRegiao());
      this.rootScope.$on('data-busca-regiao', () => this.buscaRegiaoDia = new Date());
      this.rootScope.$on('buscar-veiculo', () => this.filtro = 'ABC');
    }

    setTabPontoReferencia() {
      this.pesquisaService.tabLogradouro = false;
      this.pesquisaService.tabPontoReferencia = true;
      this.pesquisaService.tabBuscaPorRegiao = false;
      this.validaPontos = true;
    }

    tabOnlyLogradouroEnabled() {
      return this.pesquisaService.tabLogradouro &&
        !this.pesquisaService.tabPontoReferencia &&
        !this.pesquisaService.tabBuscaPorRegiao;
    }

    tabOnlyPontoReferencia() {
      return this.pesquisaService.tabPontoReferencia &&
        !this.pesquisaService.tabLogradouro &&
        !this.pesquisaService.tabBuscaPorRegiao;
    }

    tabOnlyBuscaPorRegiao() {
      return this.pesquisaService.tabBuscaPorRegiao &&
        !this.pesquisaService.tabLogradouro &&
        !this.pesquisaService.tabPontoReferencia;
    }

    getPlaceHolderInput1() {
      if (this.tabOnlyPontoReferencia()) {
        return 'ce.mapa.common.modal.pesquisaPontoReferencia.placeholderBuscarPontoCerca';
      }
      return 'ce.mapa.common.modal.pesquisaPontoReferencia.placeholderNomeLogradouro';
    }

    getLabelMainInput() {
      if (this.tabOnlyPontoReferencia()) {
        return 'ce.mapa.common.modal.pesquisaPontoReferencia.labelPontoReferencia';
      } else if (this.tabOnlyLogradouroEnabled()) {
        return 'ce.mapa.common.modal.pesquisaPontoReferencia.labelLogradouro';
      }
      return '';
    }

    setTabLogradouro() {
      this.pesquisaService.tabLogradouro = true;
      this.pesquisaService.tabPontoReferencia = false;
      this.pesquisaService.tabBuscaPorRegiao = false;
      this.validaPontos = true;
    }

    setTabBuscaPorRegiao() {
      this.pesquisaService.tabLogradouro = false;
      this.pesquisaService.tabPontoReferencia = false;
      this.pesquisaService.tabBuscaPorRegiao = true;
    }

    busca() {
      const isUserInLocalizacaoDaFrotaPage = this.window.location.href.includes('mapas-grid'),
          isUserInHistoricoDetalhadoPage = this.window.location.href.includes('historico-timeline');
      let gtmEventName;

      if (this.tabOnlyPontoReferencia()) {
        if (isUserInLocalizacaoDaFrotaPage) {
          gtmEventName = 'localizacao_frota_busca_local';
        } else if (isUserInHistoricoDetalhadoPage) {
          gtmEventName = 'historico_detalhado_busca_local';
        }

        let result = this.pesquisaService.pesquisa ?
          this.pesquisaService.pesquisa.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') :
          '';
        this.pesquisaService.pontos = [];
        this.pesquisaService.cercasEletronicas = [];
        this.pesquisaService.buscaPontoCerca = [];

        this.pontosReferenciaMapService.removeLogradouroMarker();

        if (result.length > 2) {
          this.realizaBuscaPontos(result);
          this.realizaBuscaCercasEletronicas(result);
          this.montaResultadoBusca();
        }
        this.validaPontos = this.pesquisaService.pontos.length > 0;
        this.validaPontoArea = this.pesquisaService.buscaPontoCerca.length > 0;
      } else {
        if (isUserInLocalizacaoDaFrotaPage) {
          gtmEventName = 'localizacao_frota_busca_logradouro';
        } else if (isUserInHistoricoDetalhadoPage) {
          gtmEventName = 'historico_detalhado_busca_logradouro';
        }
        this.buscaRuas();
      }

      if (gtmEventName) {
        this.googleTagManagerUtils.sendEventClickGTM(gtmEventName, this.serviceVfiltro.factoryVfiltro.user);
      }
    }

    realizaBuscaPontos(searchString) {
      this.pontosReferenciaMapService.pontosReferencia.forEach(item => {
        if (item && this.isBuscaNomeCorrespondente(item.entidade.nome, searchString)) {
          item.type = this.typePonto;
          item.disabled = !this.pontosReferenciaFiltroService.isPontoVisible(item);
          this.pesquisaService.pontos.push(item);
        }
      });
    }

    realizaBuscaCercasEletronicas(searchString) {
      this.cercaEletronicaMapService.cercasEletronicas.forEach(cercaEletronica => {
        const cercaParaPesquisa = {entidade: this.getCerca(cercaEletronica)};
        if (cercaParaPesquisa && this.isBuscaNomeCorrespondente(cercaParaPesquisa.entidade.nome, searchString)) {
          cercaParaPesquisa.type = this.typeCerca;
          cercaParaPesquisa.disabled = !this.pontosReferenciaFiltroService.isCercaVisible(cercaEletronica);
          cercaParaPesquisa.disabled = !this.pontosReferenciaFiltroService.isCercaVisible(cercaEletronica);
          this.pesquisaService.cercasEletronicas.push(cercaParaPesquisa);
        }
      });
    }

    isBuscaNomeCorrespondente(nomeEntidade, searchString) {
      return nomeEntidade
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .indexOf(searchString) !== -1;
    }

    montaResultadoBusca() {
      this.pesquisaService.buscaPontoCerca = [
        ...this.pesquisaService.pontos,
        ...this.pesquisaService.cercasEletronicas
      ].sort((a, b) => a.entidade.nome.localeCompare(b.entidade.nome));
    }

    focusLocal(item) {
      if (item.type.id === this.typePonto.id) {
        this.pontosReferenciaMapService.focusPonto(item);
      }
      if (item.type.id === this.typeCerca.id) {
        this.cercaEletronicaMapService.focusCercaEletronica(item.entidade.id);
      }
    }

    formValido(form) {
      if (this.tabOnlyPontoReferencia()) {
        return form.pesquisa.$valid;
      } else if (this.tabOnlyLogradouroEnabled()) {
        return form.pesquisa.$valid;
      }
      return false;
    }

    formBuscaPorRegiaoValido(form) {
      if (this.tabOnlyBuscaPorRegiao() && form.buscaRegiaoDia) {
        return form.buscaRegiaoDia.$valid;
      }
      return false;
    }

    buscaRuas() {
      this.buscaLogradouro();
    }

    buscaLogradouro() {
      this.pesquisaService.logradouroIndex = null;
      this.httpService
        .get(`/geocode/localizacao/search?query=${encodeURIComponent(this.pesquisaService.pesquisa.toLowerCase())}`)
        .then(
            data => {
              this.pesquisaService.logradouros = data.data;
              this.validaPontos = this.pesquisaService.logradouros.length !== 0;
            }
          );
    }

    buscaPorRegiao() {
      this.googleTagManagerUtils.sendEventClickGTM('busca_regiao', this.serviceVfiltro.factoryVfiltro.user);

      this.mostrarResultado = true;
      let params = this.getParams(this.buscaRegiaoDia);

      if (this.isDemo) {
        this.buscaRegiaoService.isDemo = true;
      }

      this.buscaRegiaoService.getList(this.buscaPorRegiaoMapService.layer.getCenter(), params)
        .then(data => {
          this.pesquisaService.buscaPorRegiao = data.data;
        })
        .catch(() => {
          this.pesquisaService.buscaPorRegiao = [];
        });
    }

    exibirFiltro(filtro) {
      this.pontosReferenciaFiltroService.filtroSelecionado = filtro;
      this.pontosReferenciaFiltroService.selecionarFiltro = true;
    }

    voltarLista() {
      this.pontosReferenciaFiltroService.filtroSelecionado = null;
      this.pontosReferenciaFiltroService.selecionarFiltro = false;
      this.mostrarResultado = false;
    }

    getNomeCategoria(item) {
      if (item.objCategoria) {
        return item.objCategoria.nome;
      }
      if (item.entidade.categoria) {
        return item.entidade.categoria.nome;
      }
      return null;
    }

    getCerca(cercaEletronica) {
      return {
        id: cercaEletronica.id,
        nome: cercaEletronica.nome,
        categoriaId: cercaEletronica.categoriaId,
        categoria: cercaEletronica.categoria
      };
    }

    desenhaArea() {
      this.buscaPorRegiaoMapService.render();
      this.isAreaBuscaRegiaoCriada = true;
    }

    getParams(dia) {
      return this.httpParamSerializer(angular.extend(
        this.serviceVfiltro.getFiltroBackendNovo(this.serviceVfiltro.filtroTopbar),
        {dia: this.moment(dia).format('YYYY-MM-DD')}
      ));
    }

    getLinkHistoricoDetalhado(buscaRegiao) {
      let uoId = this.serviceVfiltro.filtroTopbar.find(x => x.key === this.keys.uo.name).value.id,
          dataInicio = this.moment(buscaRegiao.datahora).format('YYYY.MM.DD.HH.mm.ss.ZZ'),
          dataFim = this.moment(buscaRegiao.datahora).format('YYYY.MM.DD.23.59.59.ZZ');

      return `/historico-timeline?vfiltro=dateTime:${dataInicio},${dataFim};uo:${uoId};veiculo:${buscaRegiao.veiculoId}`;
    }

    buscaPorRegiaoCancel() {
      this.buscaRegiaoDia = {};
      this.buscaPorRegiaoMapService.removeLayerMap();
      this.isAreaBuscaRegiaoCriada = false;
    }

    showBuscaPorRegiao() {
      return this.paginasPermiteBuscaRegiao.includes(this.state.current.name);
    }
  }

  angular
    .module('mapControlModule')
    .controller('PesquisaPontoReferenciaCtrl', PesquisaPontoReferenciaCtrl)
    .directive('pesquisaPontoReferenciaDirective', pesquisaPontoReferenciaDirective);
}());
