angular.module('historicoAlerta').service('CurrentViewStateService', function (FeaturehubJsSdk, FeatureHubConfigKeys) {
  const isThumbnailsViewButtonEnabled = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.FEATURE_THUMBNAILS_BUTTON);
  let currentView = isThumbnailsViewButtonEnabled ? 'grid' : 'table';

  return {
    getCurrentView: () => currentView,
    setCurrentView: (view) => {
      currentView = view;
    },
    isThumbnailsViewButtonEnabled
  };
});
