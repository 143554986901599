(function () {
  'use strict';

  /* @ngdoc object
   * @name relatorios
   * @description
   *
   */
  angular
    .module('relatorios', [
      'ui.router',
      'relatorios.rankingEconomy',
      'relatorios.rankingSafety',
      'relatorios.bdm',
      'relatorios.bdv',
      'relatorios.bds',
      'relatorios.analiseOp',
      'relatorios.historicoSolicitacao',
      'relatorios.velocidadeRpm',
      'relatorios.abastecimento',
      'relatorios.deviceHealth',
      'relatorios.permanencia',
      'relatorios.historicoComando',
      'relatorios.ocorrencia',
      'relatorios.ocorrenciaDetalhes',
      'relatorios.saudeFrota',
      'relatorios.videoLibrary',
      'relatorios.videoLibrary.solicitacaoVideos',
      'relatorios.videoLibrary.playback',
      'relatorios.identificacoes',
      'core-ce.constants',
      'onboarding',
      'angular-intro',
      'confluenceID'
    ])
    .constant('semMotorista', 'Sem Identificação')
    .constant('EQUIPAMENTO', {
      ACIONAMENTO_ELETRONICO: 198,
      ACIONAMENTO_PNEUMATICO: 199,
      SENSOR_HIDRAULICO: 391,
      SENSOR_HIDRAULICO_HR36: 537,
      SENSOR_HIDRAULICO_PV: 541,
      SENSOR_HIDRAULICO_PV_NEO: 612,
      SENSOR_HIDRAULICO_HR48: 538,
      SENSOR_HIDRAULICO_HR24: 539,
      SENSOR_HIDRAULICO_EPP36: 540
    })
    .constant('AGRUPAMENTO_EQUIPAMENTO', {
      VSAFE: 'VSAFE',
      VTALKS: 'VTALKS',
      VMOV: 'VMOV'
    })
    .constant('sortAlertasIndicadores', {
      aceleracoesBruscas: 'aceleracoes',
      banguelas: 'banguelas',
      curvasBruscas: 'curvas',
      exVelocidadeFaixa1: 'excessosVelocidade',
      exVelocidadeFaixa2: 'excessosVelocidade',
      exVelocidadeFaixa3: 'excessosVelocidade',
      frenagensBruscas: 'frenagens',
      motorOcioso: 'motorOcioso',
      rpmExcessivo: 'rpmExcessivos'
    })
    .factory('sorters', (maxChar, semMotorista) => {
      return {
        formatMotoristaNome: function (row) {
          return row.motorista === semMotorista ? maxChar : row.motorista;
        }
      };
    })
    .filter('filterMot', (semMotorista) => motoristaNome => motoristaNome === '' ? semMotorista : motoristaNome);
}());
