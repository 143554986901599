(function () {
  'use strict';
  angular.module('ModuleEventos', ['pascalprecht.translate']);

  class EventosService {

    constructor($translate) {
      this.translate = $translate;

      this.LOCALIZACAO = 'ce.enum.eventos.localizacao';
      this.EXCESSO_VELOCIDADE = {
        tipo: 'ce.enum.common.excessoVelocidade',
        icone: 'icon trimble-speeding',
        cor: 'yellow',
        descricao: 'ce.enum.common.excessoVelocidade',
        alerta: 'EXCESSO_VELOCIDADE',
        isEnabled: () => true
      };
      this.EXCESSO_VELOCIDADE1 = {
        tipo: 'ce.enum.eventos.excessoVelocidade1',
        icone: 'icon trimble-speeding',
        cor: 'yellow',
        descricao: 'ce.enum.common.excessoVelocidade',
        alerta: 'EXCESSO_VELOCIDADE',
        isEnabled: () => true
      };
      this.EXCESSO_VELOCIDADE2 = {
        tipo: 'ce.enum.eventos.excessoVelocidade2',
        icone: 'icon trimble-speeding',
        cor: 'lred',
        descricao: 'ce.enum.common.excessoVelocidade',
        alerta: 'EXCESSO_VELOCIDADE',
        isEnabled: () => true
      };
      this.EXCESSO_VELOCIDADE3 = {
        tipo: 'ce.enum.eventos.excessoVelocidade3',
        icone: 'icon trimble-speeding',
        cor: 'red',
        descricao: 'ce.enum.common.excessoVelocidade',
        alerta: 'EXCESSO_VELOCIDADE',
        isEnabled: () => true
      };
      this.EXCESSO_VELOCIDADE_VIA = {
        tipo: 'ce.enum.common.excessoVelocidadePorVia',
        icone: 'icon trimble-speeding-highway',
        cor: 'red',
        descricao: 'ce.enum.common.excessoVelocidadePorVia',
        alerta: 'EXCESSO_VELOCIDADE_POR_VIA',
        isEnabled: () => true
      };
      this.IGNICAO_LIGADA = {
        tipo: 'ce.enum.eventos.ignicaoLigada',
        icone: 'fa-key',
        cor: 'green',
        descricao: 'ce.enum.eventos.ignicaoLigada',
        isEnabled: () => true
      };
      this.RFID = {
        tipo: 'ce.enum.eventos.rfidUpperCase',
        icone: 'fa-tag',
        cor: 'blue',
        descricao: 'ce.enum.eventos.eventoDeRfid',
        isEnabled: () => true
      };
      this.ABERTURA_VIGENCIA = {
        tipo: 'ce.enum.eventos.aberturaDeVigencia',
        icone: 'fv trimble-rfid',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.aberturaDeVigencia',
        isEnabled: () => true
      };
      this.FECHAMENTO_VIGENCIA = {
        tipo: 'ce.enum.eventos.fechamentoDeVigencia',
        icone: 'fv trimble-rfid',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.fechamentoDeVigencia',
        isEnabled: () => true
      };
      this.IGNICAO_DESLIGADA = {
        tipo: 'ce.enum.eventos.ignicaoDesligada',
        icone: 'fa-key',
        cor: 'red',
        descricao: 'ce.enum.eventos.ignicaoDesligada',
        isEnabled: () => true
      };
      this.PONTO_REFERENCIA = {
        tipo: 'ce.enum.eventos.pontoDeReferencia',
        icone: 'fa-home',
        cor: 'maroon',
        descricao: 'ce.enum.eventos.pontoDeReferencia',
        isEnabled: () => true
      };
      this.INICIO_JORNADA = {
        tipo: 'ce.enum.eventos.inicioJornadaUnderscore',
        icone: 'fa-asterisk',
        cor: 'blue',
        descricao: 'ce.enum.eventos.inicioDeJornada',
        isEnabled: () => true
      };
      this.INICIO_JORNADA_AJUDANTE = {
        tipo: 'ce.enum.eventos.inicioJornadaAjudanteUnderscore',
        icone: 'fa-asterisk',
        cor: 'blue',
        descricao: 'ce.enum.eventos.inicioDeJornadaDeAjudante',
        isEnabled: () => true
      };
      this.EM_JORNADA = {
        tipo: 'ce.enum.eventos.paradoUpperCase',
        icone: 'fa-minus-circle',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.emJornada',
        isEnabled: () => true
      };
      this.DIRECAO = {
        tipo: 'ce.enum.eventos.direcaoUpperCase',
        icone: 'fa-road',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.direcao',
        isEnabled: () => true
      };
      this.REFEICAO = {
        tipo: 'ce.enum.eventos.refeicaoUpperCase',
        icone: 'fa-cutlery',
        cor: 'green',
        descricao: 'ce.enum.eventos.refeicao',
        isEnabled: () => true
      };
      this.DESCANSO = {
        tipo: 'ce.enum.eventos.descansoUpperCase',
        icone: 'fa-coffee',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.descanso',
        isEnabled: () => true
      };
      this.ESPERA = {
        tipo: 'ce.enum.eventos.esperaUpperCase',
        icone: 'fa-clock-o',
        cor: 'maroon',
        descricao: 'ce.enum.eventos.espera',
        isEnabled: () => true
      };
      this.ENTREGA = {
        tipo: 'ce.enum.eventos.entregaUpperCase',
        icone: 'fa-archive',
        cor: 'blue',
        descricao: 'ce.enum.eventos.entrega',
        isEnabled: () => true
      };
      this.RESERVA = {
        tipo: 'ce.enum.eventos.reservaUpperCase',
        icone: 'fa-retweet',
        cor: 'green',
        descricao: 'ce.enum.eventos.reserva',
        isEnabled: () => true
      };
      this.FIM_JORNADA = {
        tipo: 'ce.enum.eventos.fimJornadaUnderscore',
        icone: 'fa-power-off',
        cor: 'maroon',
        descricao: 'ce.enum.eventos.fimDeJornada',
        isEnabled: () => true
      };
      this.FIM_JORNADA_AJUDANTE = {
        tipo: 'ce.enum.eventos.fimJornadaAjudanteUnderscore',
        icone: 'fa-power-off',
        cor: 'maroon',
        descricao: 'ce.enum.eventos.fimDeJornadaDeAjudante',
        isEnabled: () => true
      };
      this.BANGUELA = {
        tipo: 'ce.enum.common.banguela',
        icone: 'icon trimble-coasting',
        cor: 'yellow',
        descricao: 'ce.enum.common.banguela',
        alerta: 'BANGUELA',
        isEnabled: () => true
      };
      this.RPM = {
        tipo: 'ce.enum.common.rpmExcessivo',
        icone: 'icon trimble-rpm',
        cor: 'yellow',
        descricao: 'ce.enum.common.rpmExcessivo',
        alerta: 'EXCESSO_RPM',
        isEnabled: () => true
      };
      this.MOTOR_OCIOSO = {
        tipo: 'ce.enum.eventos.motorOcioso',
        icone: 'icone-motor',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.motorOcioso',
        alerta: 'PARADO_LIGADO',
        isEnabled: () => true
      };
      this.ACELERACAO_BRUSCA = {
        tipo: 'ce.enum.common.aceleracaoBrusca',
        icone: 'icon trimble-harsh-acceleration',
        cor: 'yellow',
        descricao: 'ce.enum.common.aceleracaoBrusca',
        alerta: 'ACELERACAO_BRUSCA',
        isEnabled: () => true
      };
      this.FRENAGEM_BRUSCA = {
        tipo: 'ce.enum.common.frenagemBrusca',
        icone: 'icon trimble-harsh-braking',
        cor: 'yellow',
        descricao: 'ce.enum.common.frenagemBrusca',
        alerta: 'FREADA_BRUSCA',
        isEnabled: () => true
      };
      this.FRENAGEM_BRUSCA_COM_PEDAL = {
        tipo: 'ce.enum.common.frenagemBruscaComPedal',
        icone: 'icon trimble-harsh-braking',
        cor: 'yellow',
        descricao: 'ce.enum.common.frenagemBruscaComPedal',
        alerta: 'FREADA_BRUSCA',
        isEnabled: () => true
      };
      this.FRENAGEM_BRUSCA_SEM_PEDAL = {
        tipo: 'ce.enum.common.frenagemBruscaSemPedal',
        icone: 'icon trimble-harsh-braking',
        cor: 'yellow',
        descricao: 'ce.enum.common.frenagemBruscaSemPedal',
        alerta: 'FREADA_BRUSCA',
        isEnabled: () => true
      };
      this.TREPIDACAO = {
        tipo: 'ce.enum.common.trepidacao',
        icone: 'icon trimble-trepidation',
        cor: 'yellow',
        descricao: 'ce.enum.common.trepidacao',
        isEnabled: () => true
      };
      this.CURVA_BRUSCA = {
        tipo: 'ce.enum.common.curvaBrusca',
        icone: 'icon trimble-sharp-turn',
        cor: 'yellow',
        descricao: 'ce.enum.common.curvaBrusca',
        alerta: 'CURVA_BRUSCA',
        isEnabled: () => true
      };
      this.CURVA_BRUSCA_ESQUERDA = {
        tipo: 'ce.enum.eventos.curvaBruscaEsquerda',
        icone: 'icon trimble-sharp-turn',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.curvaBruscaEsquerda',
        alerta: 'CURVA_BRUSCA',
        isEnabled: () => true
      };
      this.CURVA_BRUSCA_DIREITA = {
        tipo: 'ce.enum.eventos.curvaBruscaDireita',
        icone: 'icon trimble-sharp-turn',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.curvaBruscaDireita',
        alerta: 'CURVA_BRUSCA',
        isEnabled: () => true
      };
      this.MOVIMENTO = {
        tipo: 'ce.enum.eventos.movimento',
        icone: 'fa-map-marker',
        cor: '',
        descricao: 'ce.enum.eventos.movimento',
        isEnabled: () => true
      };
      this.PARADO = {
        tipo: 'ce.enum.eventos.parado',
        icone: 'icone-parado',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.parado',
        isEnabled: () => true
      };
      this.PARADO_EX_1 = {
        tipo: 'ce.enum.eventos.paradoExc1',
        icone: 'icone-parado',
        cor: 'green',
        descricao: 'ce.enum.eventos.parado',
        isEnabled: () => true
      };
      this.PARADO_EX_2 = {
        tipo: 'ce.enum.eventos.paradoExc2',
        icone: 'icone-parado',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.parado',
        isEnabled: () => true
      };
      this.PARADO_EX_3 = {
        tipo: 'ce.enum.eventos.paradoExc3',
        icone: 'icone-parado',
        cor: 'lred',
        descricao: 'ce.enum.eventos.parado',
        isEnabled: () => true
      };
      this.PARADO_EX_4 = {
        tipo: 'ce.enum.eventos.paradoExc4',
        icone: 'icone-parado',
        cor: 'red',
        descricao: 'ce.enum.eventos.parado',
        isEnabled: () => true
      };
      this.REMOCAO_EQUIPAMENTO = {
        tipo: 'ce.enum.common.remocaoEquipamento',
        icone: 'icon trimble-power-off',
        cor: 'red',
        descricao: 'ce.enum.common.remocaoEquipamento',
        alerta: 'REMOCAO_EQUIPAMENTO',
        isEnabled: () => true
      };
      this.CONEXAO_EQUIPAMENTO = {
        tipo: 'ce.enum.eventos.conexaoEquipamento',
        icone: 'icon trimble-power-on',
        cor: 'green',
        descricao: 'ce.enum.eventos.conexaoEquipamento',
        isEnabled: () => true
      };
      this.POSSIVEL_COLISAO = {
        tipo: 'ce.enum.common.possivelColisao',
        icone: 'icon trimble-collision',
        cor: 'red',
        descricao: 'ce.enum.common.possivelColisao',
        alerta: 'POSSIVEL_COLISAO',
        isEnabled: () => true
      };
      this.DIAGNOSTICO_OBD = {
        tipo: 'ce.enum.eventos.diagnosticoObd',
        icone: 'fa-wrench',
        cor: 'dark-gray',
        descricao: 'ce.enum.eventos.diagnosticoObd',
        alerta: 'DIAGNOSTICO_OBD',
        isEnabled: () => true
      };
      this.DIAGNOSTICO_CAN = {
        tipo: 'ce.enum.eventos.diagnosticoCan',
        icone: 'fa-wrench',
        cor: 'dark-gray',
        descricao: 'ce.enum.eventos.diagnosticoCan',
        isEnabled: () => true
      };
      this.PARADO_LIGADO = {
        tipo: 'ce.enum.common.paradoLigado',
        icone: 'trimble-idling',
        cor: 'yellow',
        descricao: 'ce.enum.common.paradoLigado',
        alerta: 'PARADO_LIGADO',
        isEnabled: () => true
      };
      this.VEICULO_SEM_VIGENCIA = {
        tipo: 'ce.enum.common.semIdentificacaoMotorista',
        icone: 'fv trimble-rfid',
        cor: 'yellow',
        descricao: 'ce.enum.common.semIdentificacaoMotorista',
        alerta: 'VEICULO_SEM_VIGENCIA',
        isEnabled: () => true
      };
      this.VEICULO_SEM_SINAL = {
        tipo: 'ce.enum.common.semSinal',
        icone: 'fv trimble-connection-off',
        cor: 'red',
        descricao: 'ce.enum.common.semSinal',
        alerta: 'VEICULO_SEM_SINAL',
        isEnabled: () => true
      };
      this.SENSOR_ACIONADO = {
        tipo: 'ce.enum.eventos.sensorAcionado',
        icone: 'trimble-sensor-on',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.sensorAcionado',
        isEnabled: () => true
      };
      this.MANUTENCAO_PROGRAMADA = {
        tipo: 'ce.enum.common.manutencaoProgramada',
        icone: 'trimble-maintenance',
        cor: 'pink',
        descricao: 'ce.enum.common.manutencaoProgramada',
        alerta: 'MANUTENCAO_PROGRAMADA',
        isEnabled: () => true
      };
      this.DISTANCIA_PERIGOSA = {
        tipo: 'ce.enum.common.distanciaPerigosa',
        icone: 'trimble-distance-warning',
        cor: 'red',
        descricao: 'ce.enum.common.distanciaPerigosa',
        alerta: 'DISTANCIA_PERIGOSA',
        isEnabled: () => true
      };
      this.DISTRACAO_MOTORISTA = {
        tipo: 'ce.enum.common.distracao',
        icone: 'trimble-distraction',
        cor: 'red',
        descricao: 'ce.enum.common.distracao',
        alerta: 'DISTRACAO_MOTORISTA',
        isEnabled: () => true
      };
      this.FADIGA_MOTORISTA = {
        tipo: 'ce.enum.common.fadiga',
        icone: 'trimble-fatique',
        cor: 'red',
        descricao: 'ce.enum.common.fadiga',
        alerta: 'FADIGA_MOTORISTA',
        isEnabled: () => true
      };
      this.MANUSEIO_CELULAR = {
        tipo: 'ce.enum.common.usoDeCelular',
        icone: 'trimble-cell-phone-usage',
        cor: 'red',
        descricao: 'ce.enum.common.usoDeCelular',
        alerta: 'MANUSEIO_CELULAR',
        isEnabled: () => true
      };
      this.PREVISAO_COLISAO = {
        tipo: 'ce.enum.common.quaseColisao',
        icone: 'trimble-collision-warning',
        cor: 'red',
        descricao: 'ce.enum.common.quaseColisao',
        alerta: 'PREVISAO_COLISAO',
        isEnabled: () => true
      };
      this.USO_CIGARRO = {
        tipo: 'ce.enum.common.usoDeCigarro',
        icone: 'trimble-smoking',
        cor: 'red',
        descricao: 'ce.enum.common.usoDeCigarro',
        alerta: 'USO_CIGARRO',
        isEnabled: () => true
      };
      this.GPS_INVALIDO = {
        tipo: 'ce.enum.eventos.gpsInvalido',
        icone: 'trimble-gps-off',
        cor: 'red',
        descricao: 'ce.enum.eventos.gpsInvalido',
        isEnabled: () => true
      };
      this.MOTORISTA_AUSENTE = {
        tipo: 'ce.enum.common.semMotorista',
        icone: 'trimble-no-driver',
        cor: 'red',
        descricao: 'ce.enum.common.semMotorista',
        isEnabled: () => true
      };
      this.CAMERA_OBSTRUIDA = {
        tipo: 'ce.enum.common.cameraObstruida',
        icone: 'trimble-obstructed-camera',
        cor: 'red',
        descricao: 'ce.enum.common.cameraObstruida',
        alerta: 'CAMERA_OBSTRUIDA',
        isEnabled: () => true
      };
      this.PERMANENCIA_PONTO = {
        tipo: 'ce.enum.common.permanenciaPontoReferencia',
        icone: 'trimble-duration-stay',
        cor: 'yellow',
        descricao: 'ce.enum.common.permanenciaPontoReferencia',
        alerta: 'PERMANENCIA_PONTO',
        isEnabled: () => true
      };
      this.SEM_CINTO = {
        tipo: 'ce.enum.common.semCinto',
        icone: 'trimble-no-seatbelt',
        cor: 'yellow',
        descricao: 'ce.enum.common.semCinto',
        alerta: 'SEM_CINTO',
        isEnabled: () => true
      };
      this.RFID_NAO_CADASTRADO = {
        tipo: 'ce.enum.common.rfidNaoCadastradoLowerCase',
        icone: 'trimble-rfid-alert',
        cor: 'yellow',
        descricao: 'ce.enum.common.rfidNaoCadastrado',
        alerta: 'RFID_NAO_CADASTRADO',
        isEnabled: () => true
      };
      this.MANUTENCAO_PROGRAMADA_HORIMETRO = {
        tipo: 'ce.enum.common.manutencaoProgramadaHorimetro',
        icone: 'trimble-maintenance-hour-meter',
        cor: 'pink',
        descricao: 'ce.enum.common.manutencaoProgramadaHorimetro',
        alerta: 'MANUTENCAO_PROGRAMADA_HORIMETRO',
        isEnabled: () => true
      };
      this.PANICO = {
        tipo: 'ce.enum.common.botaoPane',
        icone: 'fa fa-exclamation-circle',
        cor: 'red',
        descricao: 'ce.enum.common.botaoPane',
        alerta: 'PANICO',
        isEnabled: () => true
      };
      this.PERMANENCIA_CERCA = {
        tipo: 'Permanência em Cerca Eletrônica',
        icone: 'trimble-duration-stay-area',
        cor: 'yellow',
        descricao: 'Permanência em Cerca Eletrônica',
        alerta: 'PERMANENCIA_CERCA',
        isEnabled: () => true
      };
      this.CONVERSAO_ILEGAL = {
        tipo: 'ce.enum.common.conversaoSemSinalizacao',
        icone: 'trimble-lane-departure-unindicated',
        cor: 'yellow',
        descricao: 'ce.enum.common.conversaoSemSinalizacao',
        alerta: 'CONVERSAO_ILEGAL',
        isEnabled: () => true
      };
      this.AVISO_LIGADO = {
        tipo: 'ce.enum.common.botaoAviso',
        icone: 'fa fa-exclamation-triangle',
        cor: 'yellow',
        descricao: 'ce.enum.common.botaoAviso',
        alerta: 'AVISO_LIGADO',
        isEnabled: () => true
      };
      this.DESCIDA = {
        tipo: 'ce.enum.common.descida',
        icone: 'material outlined_flag',
        cor: 'blue',
        descricao: 'ce.enum.common.descida',
        isEnabled: () => true
      };
      this.DESCIDA_FAIXA1 = {
        tipo: 'ce.enum.eventos.descidaFaixa1',
        icone: 'material outlined_flag',
        cor: 'blue',
        descricao: 'ce.enum.common.inicioDescida',
        isEnabled: () => true
      };
      this.DESCIDA_FAIXA2 = {
        tipo: 'ce.enum.eventos.descidaFaixa2',
        icone: 'material outlined_flag',
        cor: 'blue',
        descricao: 'ce.enum.common.inicioDescida',
        isEnabled: () => true
      };
      this.DESCIDA_FAIXA3 = {
        tipo: 'ce.enum.eventos.descidaFaixa3',
        icone: 'material outlined_flag',
        cor: 'blue',
        descricao: 'ce.enum.common.inicioDescida',
        isEnabled: () => true
      };
      this.DESCIDA_FAIXA1_FIM = {
        tipo: 'ce.enum.eventos.descidaFaixa1fim',
        icone: 'material sports_score',
        cor: 'purple',
        descricao: 'ce.enum.common.fimDescida',
        isEnabled: () => true
      };
      this.DESCIDA_FAIXA2_FIM = {
        tipo: 'ce.enum.eventos.descidaFaixa2fim',
        icone: 'material sports_score',
        cor: 'purple',
        descricao: 'ce.enum.common.fimDescida',
        isEnabled: () => true
      };
      this.DESCIDA_FAIXA3_FIM = {
        tipo: 'ce.enum.eventos.descidaFaixa3fim',
        icone: 'material sports_score',
        cor: 'purple',
        descricao: 'ce.enum.common.fimDescida',
        isEnabled: () => true
      };
      this.SEM_CONE = {
        tipo: 'ce.enum.eventos.semCone',
        icone: 'trimble-cone',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.semCone',
        alerta: 'SEM_CONE',
        isEnabled: () => true
      };
      this.SEM_OCULOS = {
        tipo: 'ce.enum.eventos.SemOculos',
        icone: 'trimble-sem-oculos',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.SemOculos',
        alerta: 'SEM_OCULOS',
        isEnabled: () => true
      };
      this.SEM_LUVAS = {
        tipo: 'ce.enum.eventos.SemLuvas',
        icone: 'trimble-luva',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.SemLuvas',
        alerta: 'SEM_LUVAS',
        isEnabled: () => true
      };
      this.BOCEJO = {
        tipo: 'ce.enum.eventos.bocejo',
        icone: 'trimble-yawning',
        cor: 'red',
        descricao: 'ce.enum.eventos.bocejo',
        alerta: 'BOCEJO',
        isEnabled: () => true
      };
      this.ULTRAPASSAGEM_ILEGAL = {
        tipo: 'ce.enum.eventos.ultrapassagemIlegal',
        icone: 'trimble-ultrapassagem-ilegal',
        cor: 'red',
        descricao: 'ce.enum.eventos.ultrapassagemIlegal',
        alerta: 'ULTRAPASSAGEM_ILEGAL',
        isEnabled: () => true
      };
      this.CARONA = {
        tipo: 'ce.enum.eventos.carona',
        icone: 'trimble-carona',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.carona',
        alerta: 'CARONA',
        isEnabled: () => true
      };
      this.FOLLOWING_DISTANCE = {
        tipo: 'ce.enum.eventos.followingDistance',
        icone: 'trimble-unit-selector1',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.followingDistance',
        alerta: 'FOLLOWING_DISTANCE',
        isEnabled: (configuracoesEventos) => !(configuracoesEventos && configuracoesEventos.followingDistance && configuracoesEventos.followingDistance.enabled === false)
      };
      this.COLLISION_MITIGATION = {
        tipo: 'ce.enum.eventos.collisionMitigation',
        icone: 'trimble-collision-warning',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.collisionMitigation',
        alerta: 'COLLISION_MITIGATION',
        isEnabled: (configuracoesEventos) => !(configuracoesEventos && configuracoesEventos.collisionMitigation && configuracoesEventos.collisionMitigation.enabled === false)
      };
      this.FCW_BRAKE_EXT_ACC_DEM = {
        tipo: 'ce.enum.eventos.fcwBrakeExtAccDem',
        icone: 'trimble-truck-warning-delay1',
        tooltip: 'ce.enum.eventosTooltips.fcwBrakeExtAccDem',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.fcwBrakeExtAccDem',
        alerta: 'FCW_BRAKE_EXT_ACC_DEM',
        isEnabled: (configuracoesEventos) => !(configuracoesEventos && configuracoesEventos.fcwBrakeExtAccDem && configuracoesEventos.fcwBrakeExtAccDem.enabled === false)
      };
      this.HAPTIC_WARNING = {
        tipo: 'ce.enum.eventos.hapticWarning',
        icone: 'trimble-traffic-cone_outline',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.hapticWarning',
        alerta: 'HAPTIC_WARNING',
        isEnabled: (configuracoesEventos) => !(configuracoesEventos && configuracoesEventos.hapticWarning && configuracoesEventos.hapticWarning.enabled === false)
      };
      this.ROP_BRAKE = {
        tipo: 'ce.enum.eventos.ropBrake',
        icone: 'trimble-between_outline',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.ropBrake',
        alerta: 'ROP_BRAKE',
        isEnabled: (configuracoesEventos) => !(configuracoesEventos && configuracoesEventos.ropBrake && configuracoesEventos.ropBrake.enabled === false)
      };

      this.ROP_ENGINE = {
        tipo: 'ce.enum.eventos.ropEngine',
        icone: 'trimble-between_outline',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.ropEngine',
        alerta: 'ROP_ENGINE',
        isEnabled: (configuracoesEventos) => !(configuracoesEventos && configuracoesEventos.ropEngine && configuracoesEventos.ropEngine.enabled === false)
      };

      this.YC_BRAKE = {
        tipo: 'ce.enum.eventos.ycBrake',
        icone: 'trimble-advanced-instructions_outline',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.ycBrake',
        alerta: 'YC_BRAKE',
        isEnabled: (configuracoesEventos) => !(configuracoesEventos && configuracoesEventos.ycBrake && configuracoesEventos.ycBrake.enabled === false)
      };

      this.YC_ENGINE = {
        tipo: 'ce.enum.eventos.ycEngine',
        icone: 'trimble-advanced-instructions_outline',
        cor: 'yellow',
        descricao: 'ce.enum.eventos.ycEngine',
        alerta: 'YC_ENGINE',
        isEnabled: (configuracoesEventos) => !(configuracoesEventos && configuracoesEventos.ycEngine && configuracoesEventos.ycEngine.enabled === false)
      };

      this.eventos = this.translateEvents([
        this.EXCESSO_VELOCIDADE,
        this.EXCESSO_VELOCIDADE1,
        this.EXCESSO_VELOCIDADE2,
        this.EXCESSO_VELOCIDADE3,
        this.EXCESSO_VELOCIDADE_VIA,
        this.RFID,
        this.IGNICAO_LIGADA,
        this.IGNICAO_DESLIGADA,
        this.PONTO_REFERENCIA,
        this.INICIO_JORNADA,
        this.INICIO_JORNADA_AJUDANTE,
        this.EM_JORNADA,
        this.DIRECAO,
        this.REFEICAO,
        this.DESCANSO,
        this.ESPERA,
        this.ENTREGA,
        this.RESERVA,
        this.FIM_JORNADA,
        this.FIM_JORNADA_AJUDANTE,
        this.BANGUELA,
        this.RPM,
        this.MOTOR_OCIOSO,
        this.ACELERACAO_BRUSCA,
        this.FRENAGEM_BRUSCA,
        this.FRENAGEM_BRUSCA_COM_PEDAL,
        this.FRENAGEM_BRUSCA_SEM_PEDAL,
        this.TREPIDACAO,
        this.CURVA_BRUSCA,
        this.CURVA_BRUSCA_ESQUERDA,
        this.CURVA_BRUSCA_DIREITA,
        this.MOVIMENTO,
        this.PARADO,
        this.PARADO_EX_1,
        this.PARADO_EX_2,
        this.PARADO_EX_3,
        this.PARADO_EX_4,
        this.REMOCAO_EQUIPAMENTO,
        this.CONEXAO_EQUIPAMENTO,
        this.POSSIVEL_COLISAO,
        this.DIAGNOSTICO_OBD,
        this.DIAGNOSTICO_CAN,
        this.PARADO_LIGADO,
        this.VEICULO_SEM_VIGENCIA,
        this.VEICULO_SEM_SINAL,
        this.ABERTURA_VIGENCIA,
        this.FECHAMENTO_VIGENCIA,
        this.SENSOR_ACIONADO,
        this.MANUTENCAO_PROGRAMADA,
        this.DISTANCIA_PERIGOSA,
        this.DISTRACAO_MOTORISTA,
        this.FADIGA_MOTORISTA,
        this.MANUSEIO_CELULAR,
        this.PREVISAO_COLISAO,
        this.USO_CIGARRO,
        this.GPS_INVALIDO,
        this.MOTORISTA_AUSENTE,
        this.CAMERA_OBSTRUIDA,
        this.PERMANENCIA_PONTO,
        this.SEM_CINTO,
        this.RFID_NAO_CADASTRADO,
        this.MANUTENCAO_PROGRAMADA_HORIMETRO,
        this.PANICO,
        this.PERMANENCIA_CERCA,
        this.CONVERSAO_ILEGAL,
        this.AVISO_LIGADO,
        this.DESCIDA,
        this.DESCIDA_FAIXA1,
        this.DESCIDA_FAIXA2,
        this.DESCIDA_FAIXA3,
        this.DESCIDA_FAIXA1_FIM,
        this.DESCIDA_FAIXA2_FIM,
        this.DESCIDA_FAIXA3_FIM,
        this.SEM_CONE,
        this.SEM_OCULOS,
        this.SEM_LUVAS,
        this.BOCEJO,
        this.ULTRAPASSAGEM_ILEGAL,
        this.CARONA,
        this.FOLLOWING_DISTANCE,
        this.ROP_BRAKE,
        this.ROP_ENGINE,
        this.YC_BRAKE,
        this.YC_ENGINE,
        this.COLLISION_MITIGATION,
        this.FCW_BRAKE_EXT_ACC_DEM,
        this.HAPTIC_WARNING
      ]);
    }

    translateEvents(eventos) {
      return eventos.map(event => ({
        tipo: this.translate.instant(event.tipo),
        icone: event.icone,
        cor: event.cor,
        descricao: this.translate.instant(event.descricao),
        alerta: event.alerta,
        isEnabled: event.isEnabled
      }));
    }

    isLocalizacao(evento) {
      return evento === this.translate.instant(this.LOCALIZACAO) || evento === this.translate.instant(this.MOVIMENTO.tipo);
    }

    isAlerta(evento) {
      return !this.isLocalizacao(evento) &&
        !this.isIgnicao(evento) &&
        !this.isPontoDeReferencia(evento) &&
        !this.isRfid(evento);
    }

    isRfid(evento) {
      return evento === this.translate.instant(this.RFID.tipo);
    }

    isIgnicaoLigada(evento) {
      return evento === this.translate.instant(this.IGNICAO_LIGADA.tipo);
    }

    isIgnicaoDesligada(evento) {
      return evento === this.translate.instant(this.IGNICAO_DESLIGADA.tipo);
    }

    isIgnicao(evento) {
      const ignicao = this.translate.instant('ce.enum.eventos.ignicao');
      return evento.indexOf(ignicao) > -1;
    }

    isDiagnosticoObd(evento) {
      const diagnosticoObd = this.translate.instant('ce.enum.eventos.diagnosticoObd');
      return evento.indexOf(diagnosticoObd) > -1;
    }

    isExcessoVelocidade(evento) {
      return evento === this.EXCESSO_VELOCIDADE.tipo ||
        evento === this.translate.instant(this.EXCESSO_VELOCIDADE1.tipo) ||
        evento === this.translate.instant(this.EXCESSO_VELOCIDADE2.tipo) ||
        evento === this.translate.instant(this.EXCESSO_VELOCIDADE3.tipo);
    }

    isCurvaBrusca(evento) {
      if (angular.isString(evento)) {
        const eventoUpperCase = evento.toUpperCase();
        return eventoUpperCase === this.translate.instant(this.CURVA_BRUSCA.tipo).toUpperCase() ||
          eventoUpperCase === this.translate.instant(this.CURVA_BRUSCA_DIREITA.tipo).toUpperCase() ||
          eventoUpperCase === this.translate.instant(this.CURVA_BRUSCA_ESQUERDA.tipo).toUpperCase();
      }
      return false;
    }

    isFrenagemBrusca(evento) {
      if (angular.isString(evento)) {
        const eventoUpperCase = evento.toUpperCase();
        return eventoUpperCase === this.translate.instant(this.FRENAGEM_BRUSCA.tipo).toUpperCase();
      }
    }

    getTipoFrenagem(acionamentoPedal) {
      switch (acionamentoPedal) {
        case true:
          return this.translate.instant(this.FRENAGEM_BRUSCA_COM_PEDAL.tipo);
        case false:
          return this.translate.instant(this.FRENAGEM_BRUSCA_SEM_PEDAL.tipo);
        default:
          return this.translate.instant(this.FRENAGEM_BRUSCA.tipo);
      }
    }

    isPontoDeReferencia(evento) {
      return evento === this.translate.instant(this.PONTO_REFERENCIA.tipo);
    }

    getEvento(tipo) {
      return this.eventos.filter(evento => evento.tipo === tipo)[0];
    }

    getByAlerta(alertaId) {
      return this.eventos.find(evento => !!evento.alerta && evento.alerta === alertaId);
    }
  }

  angular
    .module('ModuleEventos')
    .service('Eventos', EventosService);
}());
