(function () {
  'use strict';

  angular
    .module('SubpaginasEventos', [
      'InputCheckboxModule',
      'InputNumberModule',
      'ui.router',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'pascalprecht.translate',
      'alert-message',
      'restangular',
      'modalInfoConfirmModule',
      'angularMoment',
      'AlertasModule',
      'FeatureFlagModule',
      'httpServiceV3gModule',
      'validateUtilModule',
      'v3gAuthenticatorModule',
      'SolucoesModule',
      'ConfigEventosModule',
      'safetyModule'
    ]);
}());
