(function () {
  'use strict';

  angular
    .module('historicoAlerta', [
      'ui.router',
      'ui.bootstrap',
      'ngSanitize',
      'restangular',
      'historicoAlertaPaginacao',
      'validateUtilModule',
      'requisicaoVideo',
      'statusVideo',
      'videoPlayer',
      'pascalprecht.translate',
      'usuarioModule',
      'angularMoment',
      'smart-table',
      'VtabelaModule',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'UrlModule',
      'ObjetosVfiltroModule',
      'AlertasModule',
      'pascalprecht.translate',
      'urlConfigModule',
      'ConstantModule',
      'uoModule'
    ])
    .factory('RelatorioAlerta', Restangular => {
      let service = Restangular.service('alerta/relatorio');
      service.get = (uoId, params) => {
        return Restangular.service(`alerta/relatorio/uo/${uoId}`).one().get(params);
      };

      return service;
    })
    .factory('AlertsTag', Restangular => {
      let service = Restangular.service('alerta/tags');
      service.get = (ouId) => {
        return Restangular.service('alerta/tags')
          .one()
          .get({
            ouId,
            active: true,
            visible: true,
            fields: ['id', 'name'].join(',')
          });
      };

      return service;
    })
    .factory('TiposAlerta', Restangular => Restangular.service('alerta/tipos'))
    .factory('Alertas', Restangular => Restangular.service('alerta/alertas'))
    .factory('HistoricoAlerta', Restangular => Restangular.service('alerta/historico-alertas'));
}());
