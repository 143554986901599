(function () {
  'use strict';

  angular.module('ModuleConvertersUtil', [])

  .service('ConvertersService', function () {
    const KM_TO_MILE_CONVERSION = 0.621371,
        MILES_DECIMAL_CONSTANT = 0.609,
        MILE_TO_KM_CONVERSION = 1 / KM_TO_MILE_CONVERSION,
        truncateMiles = (miles) => Math.floor(miles),
        roundUpKilometers = (kilometers) => Math.ceil(kilometers),
        parseKm = (km) => {
          if (km === null || angular.isUndefined(km)) {
            return null;
          }
          const parsedKm = angular.isString(km) ? parseFloat(km) : km;
          return Number.isNaN(parsedKm) ? null : parsedKm;
        };

    this.handleKmToMiles = (km) => {
      const parsedKm = parseKm(km);
      return parsedKm === null ? km : truncateMiles(parsedKm * KM_TO_MILE_CONVERSION);
    };

    this.handleKmToMilesWithDecimal = (km, decimalCases) => {
      const parsedKm = parseKm(km);
      return parsedKm === null ? km : Number((parsedKm * KM_TO_MILE_CONVERSION).toFixed(decimalCases));
    };

    this.handleMilesToKm = (mile) => {
      if (mile === null || angular.isUndefined(mile)) {
        return mile;
      }

      const parsedMile = angular.isString(mile) ? parseFloat(mile) : mile;

      if (Number.isNaN(parsedMile)) {
        return mile;
      }

      return roundUpKilometers(parsedMile * MILE_TO_KM_CONVERSION + MILES_DECIMAL_CONSTANT);
    };
  })

  .filter('formatSpeedMeasure', function () {
    return function (speed, userSpeedMeasurement) {
      if (userSpeedMeasurement === 'MILES') {
        return `${speed} mph`;
      }
      return `${speed} KM/H`;
    };
  });
}());

